<template>
  <sh-signing-card ref="signingCard" :group-id="groupId"
                   :invited-group-id="invitedGroupId"
                   :process-uuid="processUuid"
                   :event-uuid="eventUuid"
                   authentication-type="access_token"
                   :download-base-url="downloadBaseUrl"/>
</template>

<script>
    import SigningCard from "../../pdfsign/SigningCard";

    export default {
        name : "sh-signing-pane",
        components : {
            "sh-signing-card" : SigningCard
        },
        data()
        {
            return {
                groupId : this.$router.currentRoute.query.group_id,
                invitedGroupId : this.$router.currentRoute.query.invited_group_id,
                processUuid : this.$router.currentRoute.query.process_uuid,
                eventUuid : this.$router.currentRoute.query.event_uuid,
                downloadBaseUrl : process.env.VUE_APP_SIGNFLOW_DOWNLOAD_URL
            }
        },
        /**
         * Set from property on signing card when created, so we know where to go after signing finishes.
         *
         * @param to
         * @param from
         * @param next
         */
        beforeRouteEnter ( to, from, next )
        {
            next( vm =>
            {
                vm.$refs.signingCard.from = from;
            } );
        },
    }
</script>

<style scoped>

</style>