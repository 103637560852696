<template>
  <v-expansion-panel @change="retrieveFlowData()" ref="panel" class="archive-expansion-panel">
    <v-expansion-panel-header :class="header_class">
      <table class="sh-flow-panel-header">
        <tr>
          <td class="sh-flow-panel-date-box">
            <div class="sh-flow-panel-day-created">{{ day_completed }}</div>
            <div class="sh-flow-panel-month-year">{{ month_year_completed }}</div>
          </td>
          <td class="sh-flow-panel-title py-3">
            <div v-if="!flow.title" class="sh-flow-panel-documents">
              <v-icon small color="black darken-4">mdi-file-pdf-box</v-icon>
              <span class="sh-flow-panel-document">{{ flow.documents.map( doc => doc.fileName ).join( ', ' ) }}</span>
            </div>
            <div v-if="flow.title" class="sh-flow-panel-title">
              <v-icon small color="black darken-4">mdi-asterisk</v-icon>
              <span class="sh-flow-panel-title-text">{{ flow.title }}</span>
            </div>
            <div class="sh-flow-panel-signers pt-1">
              <v-icon v-if="!isSigningInvitation" small color="black darken-4">mdi-account-plus</v-icon>
              <span v-if="!isSigningInvitation" class="sh-flow-panel-signer mr-2">{{  flow.requester.name }}</span>
              <v-icon small color="black darken-4" class="mb-1">mdi-account-group</v-icon>
              <span
                  class="sh-flow-panel-signer">{{ flow.signers.map( signer => signer.signerName ).join( ', ' ) }}</span>
            </div>
            <div v-if="isSigningInvitation" class="sh-flow-panel-signers">
              <v-icon small color="black darken-4">mdi-domain</v-icon>
              <span v-if="isSigningInvitation" class="sh-flow-panel-signer">{{  flow.requester.name }} {{ $t( "signedin.From" ) }} {{  flow.requester.groupTitle }}</span>
            </div>
            <div v-if="!isSigningInvitation && flow.tags && flow.tags.length" class="sh-flow-panel-tags d-flex">
              <v-icon small color="black darken-4" class="align-self-start tag-icon">mdi-tag</v-icon>
              <div class="d-inline-flex flex-wrap">
                <v-chip v-for="tag in sorted_tags" :key="tag" class="ml-2 mr-2 mt-1 tag-chip" label >{{ tag }}</v-chip>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="archive-panel-content">
      <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
      <v-card flat v-if="!!flow_data">
        <v-responsive class="text-left pt-4 pb-7 pl-5">
          <h4 class="font-weight-bold mb-1">{{ $t( "signflow.CreatedByTitle" ) }}</h4>
          <p>{{ flow_data.requester.name }} ({{ flow_data.requester.email }}) |
            {{ dateFormat( new Date( flow_data.created ), "dd.mm.yyyy, HH:MM" ) }}</p>
          <h4 v-if="isSigningInvitation" class="font-weight-bold">{{ $t( "signflow.OwnedBy" ) }}</h4>
          <p v-if="isSigningInvitation">{{ flow_data.requester.groupTitle }}</p>
          <div v-if="flow.canceled">
            <h4 class="font-weight-bold mb-1">{{ $t( "signflow.CanceledByTitle" ) }}</h4>
            <p>
              {{ canceled_by_name }} ({{ canceled_by_email }}) | {{ completion_time }}
            </p>
            <p>{{ canceled_by_reason }}</p>
          </div>
          <div v-if="signing_completed_events.length > 0 || me_signer">
            <h4 class="font-weight-bold mb-1">{{ $t( "signflow.SignedBy" ) }}</h4>
            <div v-if="me_signer">
              <p class="mb-2">
                <span
                    v-if="!my_audit_event.sig_type || my_audit_event.sig_type === 'email-based-signature'">{{ my_audit_event.user_name }} ({{ my_audit_event.user_email }})
                </span>
                <span v-if=" my_audit_event.sig_type && my_audit_event.sig_type !== 'email-based-signature'">{{ my_audit_event.sig_signer_name }}</span>
                <span v-if="my_audit_event.sig_type && my_audit_event.sig_type !== 'email-based-signature'" class="ml-1" my_audit_event.sig_type>| {{ $t( "signedin.SignatureType!" + my_audit_event.sig_type ) }}</span>
                <span v-if="my_audit_event.sig_type === 'fi-ftn-advanced-signature'" class="ml-1">
                    <v-icon small color="black darken-4" @click="toggleSignatureValidateFilesDialog( my_audit_event )">mdi-file-certificate-outline</v-icon>
                </span>
                | {{ dateFormat( my_audit_event.time, "dd.mm.yyyy, HH:MM" ) }}
              </p>
            </div>
            <div v-if="signing_completed_events.length > 0">
              <p v-for="auditEvent in signing_completed_events" class="mb-2">
                <span v-if="!auditEvent.sig_type || auditEvent.sig_type === 'email-based-signature'">{{ auditEvent.user_name }} ({{ auditEvent.user_email }})</span>
                <span v-if="auditEvent.sig_type && auditEvent.sig_type !== 'email-based-signature'">{{ auditEvent.sig_signer_name }}</span>
                <span v-if="auditEvent.sig_type && auditEvent.sig_type !== 'email-based-signature'" class="ml-1">| {{ $t( "signedin.SignatureType!" + auditEvent.sig_type ) }}</span>
                <span v-if="auditEvent.sig_type === 'fi-ftn-advanced-signature'" class="ml-1">
                  <v-icon small color="black darken-4" @click="toggleSignatureValidateFilesDialog( auditEvent )">mdi-file-certificate-outline</v-icon>
                </span>
                | {{ dateFormat( auditEvent.time, "dd.mm.yyyy, HH:MM" ) }}
                <span v-if="!isSigningInvitation && auditEvent.user_email !== flow_data.requester.email"
                      class="send-reminder-link">
                | <v-icon class="mx-1">mdi-send-outline</v-icon>
                  <a @click="sendDownloadInvitation( auditEvent )">{{ $t( "signflow.ResendDownloadInvitation" ) }}</a>
                </span>
              </p>
            </div>
          </div>
          <h4 class="font-weight-bold mt-4 mb-1">{{ $t( "signflow.SignedDocuments" ) }}</h4>
          <div v-for="doc in flow_data.documents" class="documents-name-list">
            <v-icon small class="mr-1">mdi-file-pdf-box</v-icon>
            <a :href="downloadLink( doc )" target="_blank">{{ doc.fileName }}</a>
          </div>
          <h4 v-if="!isSigningInvitation" class="font-weight-bold mt-4 mb-1">{{ $t( "signflow.Tags" ) }}</h4>
          <div v-if="!isSigningInvitation">
            <v-icon small class="mr-1">mdi-tag</v-icon>
            <a @click="manageTags()" class="text-decoration-underline">{{ $t( "signflow.ManageTags" ) }}</a>
          </div>
        </v-responsive>
        <v-divider></v-divider>
        <v-card-actions class="operation-panel">
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="4" class="pa-2">
              <v-btn class="secondary-btn" block @click="deleteFlow()">{{ $t( "signflow.DeleteProcess" ) }}</v-btn>
            </v-col>
            <v-col class="hidden-sm-and-down" cols="4">
              <v-spacer/>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="pa-2">
              <v-btn class="alternative-primary-btn ml-auto" block @click="restartFlow()" v-if="flow.createdByMyOrg">{{
                $t( "signflow.RestartProcess" ) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-dialog v-model="signature_validation_files_dialog" width="auto">
          <v-card>
            <v-card-title>
              <b>{{ $t( 'signflow.SignatureValidationFiles' ) }}</b>
              <v-spacer></v-spacer>
              <v-btn icon @click="toggleSignatureValidateFilesDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="text-left" style="min-height: 50vh;">
              <v-divider class="ma-2"/>
              <span>{{ $t( "signflow.SignatureValidationFilesDesc" ) }}</span>
              <h4 class="font-weight-bold mt-4 mb-1">{{ $t( "signflow.SignedBy" ) }}</h4>
              <span>{{ this.selected_audit_event?.sig_signer_name }}</span>
              <h4 class="font-weight-bold mt-4 mb-1">{{ $t( "signflow.FtnSignatureTitle" ) }}</h4>
              <v-icon small>mdi-file-xml-box</v-icon>
              <a :href="downloadSignatureXmlLink()" target="_blank">
                {{ $t( 'signflow.FtnSignatureXmlFile' ) }}
              </a>
              <h4 class="font-weight-bold mt-4 mb-1">{{ $t( "signflow.UnsignedDocuments" ) }}</h4>
              <div v-for="(doc, index) in unsigned_docs" :key="index" class="documents-name-list">
                <v-icon small class="mr-1">mdi-file-pdf-box</v-icon>
                <a :href="downloadUnsignedLink( doc )" target="_blank">{{ doc.fileName }}</a>
                <p v-if="doc.digest" class="pt-2">{{ $t( "signflow.DigestTitle" ) }} {{ doc.digest }}</p>
              </div>
              <div class="text-right generate-digest-div">
                <v-btn small @click="generateDigests()" class="generate-digest-btn">{{ $t( "signflow.GenerateDigests" ) }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
    import dateFormat from "dateformat";
    import Vue from "vue";
    import {globalEvents} from "@cloudlace/client";
import util from "@/util/util";

    let onServerMessage;
    export default {
        name : "sh-ongoing-flow",
        props : {
            flow : Object,
            isSigningInvitation : Boolean,
        },
        data()
        {
            const toc = new Date( this.flow.toc );
            const completedMonth = toc.getMonth() + 1;
            return {
                loading : false,
                current_group_id : $cc_user_info.organization.uuid,
                flow_data : undefined,
                my_email : $cc_user_info.email,
                curLocale : util.getLocale(),
                canceled_by_name : "",
                canceled_by_email : "",
                canceled_by_reason : "",
                completion_time : "",
                signing_completed_events : [],
                my_audit_event : undefined,
                me_signer : false,
                day_completed : toc.getDate() < 10 ? "0" + toc.getDate() : toc.getDate(),
                month_year_completed : (completedMonth < 10 ? "0" + completedMonth : completedMonth)
                                       + "."
                                       + toc.getFullYear(),
                signature_validation_files_dialog : false,
                selected_audit_event : {}
            }
        },
        computed : {
            /**
             * Class for header pane so we can show a visual difference between canceled and completed flows.
             *
             * @returns {string}
             */
            header_class()
            {
                if( this.flow.canceled )
                {
                    return "sh-flow-panel-header sh-flow-canceled";
                }
                else
                {
                    return "sh-flow-panel-header"
                }
            },
            /**
             * Sort tags if tags exist.
             *
             * @returns {array}
             */
            sorted_tags()
            {
                if( this.flow.tags && this.flow.tags.length )
                {
                    return  this.flow.tags.sort( ( a, b ) => a.localeCompare( b, undefined, { caseFirst: "upper" } ) );
                }
                else
                {
                    return [];
                }
            },
            /**
             * Return unsigned docs.
             *
             * @returns {[]|*}
             */
            unsigned_docs()
            {
                return this.flow_data.documents;
            }
        },
        methods : {
            /**
             * Date formatter.
             */
            dateFormat : dateFormat,
            /**
             * Send download invitation matching auditEvent.
             *
             * @param auditEvent {Object}
             */
            sendDownloadInvitation( auditEvent )
            {
                const text = this.$t( "signflow.DownloadInvitationEmailSuccess" );
                const acceptText = this.$t( "signflow.Ok" );
                this.$store.signFlowClient.createDownloadInvitation( this.flow_data.groupId, this.flow_data.uuid, auditEvent.event_uuid ).then( () =>
                {
                    this.$alert( { text, acceptText } );
                } ).catch( e => this.$error( this.$t( "signflow.DownloadInvitationEmailFail" ) ) );
            },
            /**
             * Passcode confirm and then delete the sign flow.
             */
            deleteFlow()
            {
                const code = ( "" + ( Math.random() * 1000000000)).substring( 1, 5 );
                const text = this.$t( "signflow.ConfirmDeleteProcess" ) + '\n\n' + this.$t( "signflow.toProceedEnterCode" ) + code;
                const cancelText = this.$t( "signflow.ButtonGoBack" );
                const acceptText = this.$t( "signflow.Confirm" );
                this.$prompt( { text, cancelText, acceptText } ).then( r =>
                {
                    if( r === code )
                    {
                        let prom = ( this.current_group_id === this.flow.groupId ) ? this.$store.signFlowClient.deleteSignFlow( this.current_group_id, this.flow.uuid ) :
                        this.$store.signFlowClient.deleteInvitedSignFlow( this.current_group_id, this.flow.groupId, this.flow.uuid );
                        prom.then( r => {} ).catch( e => {} );
                    }
                    else
                    {
                        this.$warn( this.$t( "signflow.numbersDidNotMatch" ) );
                    }
                } ).catch( e => {} );
            },
            manageTags()
            {
                this.$emit( "manageTags", this.flow );
            },
            /**
             * Go to start page to restart the sign flow.
             */
            restartFlow()
            {
                this.$router.push( `/start?group_id=${this.flow.groupId}&process_uuid=${this.flow.uuid}&event_id=restart` );
            },
            /**
             * Download link for document.
             *
             * @param doc
             * @returns {string}
             */
            downloadLink( doc )
            {
                return process.env.VUE_APP_SIGNFLOW_DOWNLOAD_URL
                       + `${this.flow.groupId}/${this.flow.uuid}/${doc.uuid}/${doc.fileName}`;
            },
            /**
             * Download unsigned pdf link for document.
             *
             * @param doc
             * @returns {string}
             */
            downloadUnsignedLink( doc )
            {
                return process.env.VUE_APP_SIGNFLOW_ORIGINAL_DOWNLOAD_URL
                       + `${this.flow.groupId}/${this.flow.uuid}/${doc.uuid}/${doc.fileName}`;
            },
            /**
             * Download signature xml link for document.
             *
             * @param signer
             * @returns {string}
             */
            downloadSignatureXmlLink()
            {
                return process.env.VUE_APP_SIGNFLOW_XML_DOWNLOAD_URL
                       + `${this.flow.groupId}/${this.flow.uuid}/${this.selected_audit_event.event_uuid}/signature.xml`;
            },
            /**
             * Generate digests.
             */
            generateDigests()
            {
                const proms = this.flow_data.documents.map( doc =>
                {
                    return this.$store.signFlowClient.createUnsignedDigest( this.flow_data.groupId, this.flow_data.uuid, this.selected_audit_event.event_uuid, doc.uuid );
                } );
                Promise.all( proms ).then( entities =>
                {
                    let docs = this.flow_data.documents.slice();
                    for( let index = 0; index < entities.length; index++ )
                    {
                        docs[ index ].digest = entities[ index ].digest;
                    }
                    this.flow_data.documents = docs;
                } ).catch( e => this.$error( this.$t( "signflow.Error!Unexpected" ) ) );
            },
            /**
             * Toggle signature validate files dialog.
             *
             * @param audit_event
             */
            toggleSignatureValidateFilesDialog( audit_event )
            {
                if( audit_event )
                {
                    this.selected_audit_event = audit_event;
                }
                this.signature_validation_files_dialog = !this.signature_validation_files_dialog;
            },
            /**
             * Retrieve sign flow content. If reload is set, don't display the loading indicator, just quietly refresh
             * the data instead.
             *
             * @param reload
             */
            retrieveFlowData( reload )
            {
                if( reload || !this.$refs.panel.isActive )
                {
                    if( !reload )
                    {
                        this.loading = true;
                    }
                    let prom = (this.current_group_id === this.flow.groupId ) ? this.$store.signFlowClient.retrieveSignFlow( this.current_group_id, this.flow.uuid ) :
                    this.$store.signFlowClient.retrieveInvitedSignFlow( this.current_group_id, this.flow.groupId, this.flow.uuid );
                    prom.then( r =>
                    {
                        this.my_audit_event = r.auditEvents.filter( auditEvent => auditEvent.user_email === this.my_email && auditEvent.event_type === 'signing_completed')[0];
                        this.me_signer = !!this.my_audit_event;
                        this.signing_completed_events = this.sortAuditEventsByName(
                        r.auditEvents.filter( auditEvent => auditEvent.user_email !== this.my_email && auditEvent.event_type === 'signing_completed' ) );
                        if( r.declined )
                        {
                            const evt = r.signEvents.filter( signEvent => signEvent.declined === true )[ 0 ];
                            if( !evt )
                            {
                                log.error( "Expected declined sign event!" );
                                this.$error( this.$t( "signflow.Error!Unexpected" ) );
                                return;
                            }
                            this.canceled_by_name = evt.signerName;
                            this.canceled_by_email = evt.signerEmail;
                            this.canceled_by_reason = r.declinationReasons;
                        }
                        else if( r.canceled && r.decider )
                        {
                            this.canceled_by_name = r.decider.name;
                            this.canceled_by_email = r.decider.email;
                            this.canceled_by_reason = r.cancellationReasons;
                        }
                        if( r.toc )
                        {
                            this.completion_time = dateFormat( new Date( r.toc ), 'dd.mm.yyyy, HH:MM' );
                        }
                        this.loading = false;
                        this.flow_data = r;
                        this.flow.createdByMe = ( this.flow_data.requester.email === this.my_email );
                        this.flow.createdByMyOrg = ( this.flow_data.groupId === this.current_group_id );
                    } ).catch( e =>
                    {
                        console.error( e );
                        this.$error( this.$t( "signflow.Error!Unexpected" ) )
                    } );
                }
            },
            /**
             * In place sort of arrays of events by signer name
             *
             * @param events {Object[][]}
             * @returns {*}
             */
            sortAuditEventsByName ( events )
            {
                if( !events || events.length === 0 )
                {
                    return events;
                }
                if( events && events[ 0 ]?.user_name )
                {
                    return events.sort( ( a, b ) => a.user_name.localeCompare( b.user_name, this.curLocale, { caseFirst: "upper" } ) );
                }
                else
                {
                    return events.sort( ( a, b ) => a.sig_signer_name.localeCompare( b.sig_signer_name, this.curLocale, { caseFirst: "upper" } ) );
                }
            },
            /**
             * Reloading content prompted by server message, if it's changed.
             *
             * @param evt {Event}
             * @private
             */
        }
    }
</script>

<style lang="sass" scoped>
  @import '../../../../styles/style'

  .sh-flow-canceled .sh-flow-panel-date-box, .sh-flow-canceled
    color: $text-black

  .sh-flow-canceled .sh-flow-panel-date-box
    background: $light-grey

  .tag-icon
    padding-top: 6px

  .generate-digest-div
    position: absolute
    bottom: 20px
    right: 20px

  .generate-digest-btn
    background-color: $vivid-pink !important
    color: white

  @media (max-width: 599px)
    button
      width: 100%
</style>
