<template>
  <v-expansion-panel @change="retrieveFlowData()" ref="panel" class="ongoing-expansion-panel">
    <v-expansion-panel-header class="sh-flow-panel-header">
      <table class="sh-flow-panel-header">
        <tr>
          <td class="sh-flow-panel-date-box">
            <div class="sh-flow-panel-day-created">{{ day_created }}</div>
            <div class="sh-flow-panel-month-year">{{ month_year_created }}</div>
          </td>
          <td class="sh-flow-panel-title">
            <div v-if="!flow.title" class="sh-flow-panel-documents">
              <v-icon small color="black darken-4">mdi-file-pdf-box</v-icon>
              <span class="sh-flow-panel-document">{{ flow.documents.map( doc => doc.fileName ).join( ', ' ) }}</span>
            </div>
            <div v-if="flow.title" class="sh-flow-panel-title">
              <v-icon small color="black darken-4">mdi-asterisk</v-icon>
              <span class="sh-flow-panel-title-text">{{ flow.title }}</span>
            </div>
            <div class="sh-flow-panel-signers">
              <v-icon small color="black darken-4">mdi-account-group</v-icon>
              <span class="sh-flow-panel-signer">{{ flow.signers.map( signer => signer.signerName ).join( ', ' ) }}</span>
            </div>
            <div v-if="!isSigningInvitation" class="sh-flow-panel-signers">
              <v-icon small color="black darken-4">mdi-account-plus</v-icon>
              <span v-if="!isSigningInvitation" class="sh-flow-panel-signer">{{ flow.requester.name }}</span>
            </div>
            <div v-if="isSigningInvitation" class="sh-flow-panel-signers">
              <v-icon small color="black darken-4">mdi-domain</v-icon>
              <span v-if="isSigningInvitation" class="sh-flow-panel-signer">{{  flow.requester.name }} {{ $t( "signedin.From" ) }} {{  flow.requester.groupTitle }}</span>
            </div>
          </td>
        </tr>
      </table>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="ongoing-panel-content">
      <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
      <v-card flat v-if="!!flow_data">
        <v-responsive>
          <v-row class="text-left pt-4 px-5">
            <v-col cols="12" sm="6">
              <h4 class="font-weight-bold">{{ $t( "signflow.CreatedByTitle" ) }}</h4>
              <p>{{ flow_data.requester.name }} ({{ flow_data.requester.email }})<br/>
                {{ dateFormat( new Date( flow_data.created ), "dd.mm.yyyy, HH:MM" ) }}</p>
              <h4 v-if="isSigningInvitation" class="font-weight-bold">{{ $t( "signflow.OwnedBy" ) }}</h4>
              <p v-if="isSigningInvitation">{{ flow_data.requester.groupTitle }}</p>
            </v-col>
            <v-col cols="12" sm="6">
              <h4 class="font-weight-bold">{{ $t( "signflow.Documents" ) }}</h4>
              <div v-for="doc in flow_data.documents" class="documents-name-list">
                <v-icon small>mdi-file-pdf-box</v-icon>
                <a :href="downloadLink( doc )" target="_blank">{{ doc.fileName }}</a>
              </div>
            </v-col>
          </v-row>
          <v-row class="text-left px-5">
            <v-col cols="12" sm="12">
              <h4 class="font-weight-bold">{{ $t( 'signflow.SignerStatusTitle' )}}</h4>
              <div v-if="pending_me">
                <p v-html="$t( 'signflow.PendingMeAlert' )"></p>
                <p>
                  <v-btn class="primary-btn" @click="goToSign()">{{ $t( "signflow.GoToSign" ) }}</v-btn>
                </p>
              </div>
              <div v-if="pending_others && !isSigningInvitation" class="other-pending-signers">
                <v-list-group class="mt-5">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title><span v-html="pending_others_alert"/></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <div>
                    <v-list-item class="py-2" v-for="signer in pending_other_signers" :key="signer.signerEmail">
                      <v-row class="text-left">
                        <v-col cols="12" sm="6" class="status-signer">
                          {{ signer.signerName }} ({{ signer.signerEmail }})
                        </v-col>
                        <v-col cols="12" sm="6" class="status-info">
                          <div v-if="signer.loginTime">
                            <v-icon small>mdi-clock-outline</v-icon>
                            {{ $t( 'signflow.SignerStatus1' )}} {{ dateFormat( signer.loginTime, "dd.mm.yyyy, HH:MM" ) }}.
                          </div>
                          <div v-if="!signer.loginTime && flow_data.requester.email !== signer.signerEmail && flow_data.created >= new_card_timestamp">
                            <v-icon small>mdi-dots-horizontal-circle-outline</v-icon>
                            {{ $t( 'signflow.SignerStatus2' )}}
                          </div>
                          <div v-if="!signer.loginTime && flow_data.requester.email !== signer.signerEmail && flow_data.created < new_card_timestamp">
                            <v-icon small>mdi-dots-horizontal-circle-outline</v-icon>
                            {{ $t( 'signflow.SignerStatus5' )}}
                          </div>
                          <div v-if="!signer.loginTime && flow_data.requester.email === signer.signerEmail">
                            <v-icon small>mdi-dots-horizontal-circle-outline</v-icon>
                            {{ $t( 'signflow.SignerStatus4' )}}
                          </div>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item class="py-2" v-for="signer in signed_signers" :key="signer.signerEmail">
                    <v-row class="text-left">
                      <v-col cols="12" sm="6">
                        {{ signer.signerName }} ({{ signer.signerEmail }})
                      </v-col>
                      <v-col cols="12" sm="6">
                        <div v-if="signer.signed">
                          <v-icon small>mdi-check</v-icon>
                          {{ $t( 'signflow.SignerStatus3' )}} {{ dateFormat( signer.toc, "dd.mm.yyyy, HH:MM" ) }}</div>
                      </v-col>
                    </v-row>
                    </v-list-item>
                  </div>
                </v-list-group>
              </div>
              <div v-if="pending_others && !isSigningInvitation" class="other-pending-signers">
                <v-list-group class="mt-5" v-if="email_reminders.length > 0">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title><span>{{ $t( 'signflow.ReminderHistory' )}}</span></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <div>
                    <v-list-item class="py-2" v-for="email_reminder in email_reminders" :key="email_reminder.reminderTs" >
                        <v-row class="text-left">
                            <v-col cols="12" sm="8">
                                <span class="reminder-history-span">
                                    <span class="font-weight-bold reminder-history-signer-span"> {{
                                        email_reminder.reminder_ts
                                        }} </span>
                                    <span>(</span>
                                    <span :title="signer.signerEmail" class="reminder-history-signer-span"
                                          v-for="(signer, index) in formulateReminderSigners( email_reminder.reminder_list )">
                                        <template
                                                v-if="index !== (formulateReminderSigners( email_reminder.reminder_list ).length -1 )">{{
                                            signer.signerName
                                            }}, &nbsp;
                                        </template>
                                        <template
                                                v-if="index === (formulateReminderSigners( email_reminder.reminder_list ).length -1 )">{{
                                            signer.signerName
                                            }})
                                        </template>
                                    </span>
                                </span>
                            </v-col>
                            <v-col v-if="email_reminder.reminder_sender_mail && email_reminder.reminder_sender_mail !== current_mail" class="hidden-xs-only">
                                <span class="hidden-xs-only reminder-history-span" :title="email_reminder.reminder_sender_mail">{{ $t( "signflow.ReminderSentBy" ) }} {{ email_reminder.reminder_sender_name }}</span>
                            </v-col>
                        </v-row>
                    </v-list-item>
                  </div>
                </v-list-group>
                <p v-if="!isSigningInvitation" class="mt-3" v-html="last_email_reminder"></p>
                <p v-if="!isSigningInvitation">
                  <v-btn class="primary-btn" @click="sendReminders()">{{ $t( "signflow.SendReminders" ) }}
                  </v-btn>
                </p>
              </div>
              <div v-if="pending_others && isSigningInvitation" class="other-pending-signers">
                <p v-html="pending_others_alert"></p>
              </div>
            </v-col>
          </v-row>
        </v-responsive>
        <v-divider></v-divider>
        <v-card-actions v-if="!isSigningInvitation" class="operation-panel" >
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="4" class="pa-2">
              <v-btn @click="preAbortSignFlow()" class="secondary-btn" block>{{ $t( "signflow.CancelProcess" ) }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <signhero-dialog :message="dialogMessage" :resolve="abortSignFlow" type="prompt" v-if="showPromptDialog" ></signhero-dialog>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
    import dateFormat from "dateformat";
    import Vue from "vue";
    import {globalEvents} from "@cloudlace/client";
    import SignHeroDialog from "../../../../util/SignHeroDialog";
import util from "@/util/util";

    let onServerMessage;
    export default {
        name : "sh-ongoing-flow",
        props : {
            flow : Object,
            isSigningInvitation : Boolean,
        },
        data()
        {
            const created = new Date( this.flow.created );
            const createdMonth = created.getMonth() + 1;
            return {
                loading : false,
                current_group_id : $cc_user_info.organization.uuid,
                current_mail : $cc_user_info.email,
                curLocale : util.getLocale(),
                pending_me : false,
                pending_others : false,
                pending_other_signers : [],
                signed_signers : [],
                flow_data : undefined,
                last_email_reminder : "",
                email_reminders : [],
                pending_others_alert : "",
                signers_maps : {},
                day_created : created.getDate() < 10 ? "0" + created.getDate() : created.getDate(),
                month_year_created : ( createdMonth < 10 ? "0" + createdMonth : createdMonth ) + "." + created.getFullYear(),
                dialogMessage : {
                    title : this.$t( "signflow.CancelProcessDialogTitle" ),
                    text : this.$t( "signflow.CancellationReasonsPlaceHolder" ),
                    cancelText : this.$t( "signflow.ButtonGoBack" ),
                    acceptText : this.$t( "signflow.Confirm" ),
                },
                showPromptDialog : false,
                new_card_timestamp : process.env.VUE_APP_NEW_CARD_RELEASE_TIMESTAMP ? process.env.VUE_APP_NEW_CARD_RELEASE_TIMESTAMP : 1669302900000
            }
        },
        components : {
            "signhero-dialog" : SignHeroDialog
        },
        methods : {
            /**
             * Date formatter.
             */
            dateFormat : dateFormat,
            /**
             * Pre cancel a sign flow.
             */
            preAbortSignFlow()
            {
                this.showPromptDialog = true;
            },
            /**
             * If confirmed by user, cancel the sign flow.
             *
             * @param reasons
             */
            abortSignFlow( reasons )
            {
                this.showPromptDialog = false;
                if( reasons === false )
                {
                    return;
                }
                this.$store.signFlowClient.abortSignFlow( this.flow.groupId, this.flow.uuid, reasons ).then( r =>
                {
                    this.$inform( this.$t( "pdfsign.SignEventCancelled" ) );
                    this.$emit( "change", "canceled" );
                } ).catch( e =>
                {
                    this.$error( this.$t( "signflow.Error!Unexpected" ) )
                } );
            },
            /**
             * Go to the signing pane.
             */
            goToSign()
            {
                this.$router.push( `/sign?group_id=${this.current_group_id}&invited_group_id=${this.flow.groupId}&process_uuid=${this.flow.uuid}&event_uuid=${this.my_sign_event.uuid}` );
            },
            /**
             * Download link for doc.
             *
             * @param doc
             * @returns {string}
             */
            downloadLink( doc )
            {
                return process.env.VUE_APP_SIGNFLOW_DOWNLOAD_URL
                       + `${this.flow.groupId}/${this.flow.uuid}/${doc.uuid}/${doc.fileName}`;
            },
            /**
             * Send signing reminders to all signers who haven't yet signed.
             */
            sendReminders()
            {
                const text = this.$t( "signflow.EmailReminderSuccess" );
                const acceptText = this.$t( "signflow.Ok" );
                this.$store.signFlowClient.createSignReminders( this.flow.groupId, this.flow.uuid ).then( r =>
                {
                    this.$alert( { text, acceptText } );
                } ).catch( e =>
                {
                    this.$error( this.$t( "signflow.Error!Unexpected" ) )
                } );
            },
            /**
             * Retrieve/refresh sign flow content. If reload is set, don't show loader, just refresh quietly.
             *
             * @param reload
             */
            retrieveFlowData( reload )
            {
                if( reload || !this.$refs.panel.isActive )
                {
                    if( !reload )
                    {
                        this.loading = true;
                    }
                    let prom = ( this.current_group_id === this.flow.groupId ) ? this.$store.signFlowClient.retrieveSignFlow( this.current_group_id, this.flow.uuid ) :
                    this.$store.signFlowClient.retrieveInvitedSignFlow( this.current_group_id, this.flow.groupId, this.flow.uuid );
                    prom.then( r =>
                    {
                        this.loading = false;
                        this.my_sign_event =
                        r.signEvents.filter( signEvent => signEvent.signerEmail === $cc_user_info.email && !signEvent.signed )[ 0 ];
                        this.pending_other_signers = this.sortEventsByName(
                        r.signEvents.filter( signEvent => signEvent.signerEmail !== $cc_user_info.email && !signEvent.signed ) );
                        this.signed_signers = this.sortEventsByName(
                        r.signEvents.filter( signEvent => signEvent.signerEmail !== $cc_user_info.email && signEvent.signed ) );
                        const count = this.pending_other_signers.length;
                        r.signEvents.map( entity => {
                            this.signers_maps[ entity.signerEmail ] = entity.signerName;
                        });
                        this.pending_others = count > 0;
                        this.pending_me = !!this.my_sign_event;
                        const timestamp = r.lastEmailReminder ? dateFormat( r.lastEmailReminder,
                        "dd.mm.yyyy, HH:MM" ) : this.$t(
                        "signflow.NoRemindersYet" );
                        this.pending_others_alert = eval( '`' + this.$t( "signflow.PendingOthersAlert" ) + '`' );
                        this.last_email_reminder = eval( '`' + this.$t( "signflow.LastEmailReminder" ) + '`' );
                        this.flow_data = r;
                        this.email_reminders = r.emailReminders ? r.emailReminders.map( entity => {
                            return { reminder_ts: dateFormat( entity.reminderTs, "dd.mm.yyyy, HH:MM" ),
                            reminder_sender_name: entity.reminderSenderName, reminder_sender_mail: entity.reminderSenderMail,
                                reminder_list : entity.reminderList.join(', ')}
                        }) : [];
                    } ).catch( e => this.$error( this.$t( "signflow.Error!Unexpected" ) ) );
                }
            },
            /**
             * Formulate reminders signers.
             *
             * @param emailList
             * @returns {*}
             */
            formulateReminderSigners( emailList )
            {
                const reminderSigners = emailList.split( ", " ).map( email => {
                    return { signerEmail : email, signerName : this.signers_maps[ email ] };
                })
                return reminderSigners.sort( ( a, b ) => a.signerName.localeCompare( b.signerName, this.curLocale, { caseFirst: "upper" } ) );
            },
            /**
             * In place sort of arrays of events by signer name
             *
             * @param events {Object[][]}
             * @returns {*}
             */
            sortEventsByName ( events )
            {
                return events.sort( ( a, b ) => a.signerName.localeCompare( b.signerName, this.curLocale, { caseFirst: "upper" } ) );
            },
            /**
             * Reloading content prompted by server message, if it's changed.
             *
             * @param evt {Event}
             * @private
             */
            _onServerMessage( evt )
            {
                const msg = evt.detail;
                if( msg.action_str !== "ABORTED" && msg.response_map.processUuid === this.flow.uuid )
                {
                    this.retrieveFlowData( true );
                }
            }
        },
        /**
         * Subscribe to relevant server messages.
         */
        mounted()
        {
            this.showPromptDialog = false;
            onServerMessage = this._onServerMessage.bind( this );
            globalEvents.on( "shio-signflow-notification", onServerMessage );
        },
        /**
         * Remove server message listener.
         */
        beforeDestroy()
        {
            globalEvents.remove( "shio-signflow-notification", onServerMessage );
        }
    }
</script>

<style lang="sass">
  @import '../../../../styles/style'

  @media (max-width: 374px)
    .sh-flow-panel-signers
      max-width: 150px

    .sh-flow-panel-documents
      max-width: 150px

  @media (min-width: 375px)
    .sh-flow-panel-signers
      max-width: 190px

    .sh-flow-panel-documents
      max-width: 200px

  @media (min-width: 600px)
    .sh-flow-panel-signers
      max-width: 420px

    .sh-flow-panel-documents
      max-width: 420px

  @media (min-width: 700px)
    .sh-flow-panel-documents
      max-width: 520px

  @media (min-width: 960px)
    .sh-flow-panel-signers
      max-width: 720px

    .sh-flow-panel-documents
      max-width: 720px

  @media (min-width: 1264px)
    .sh-flow-panel-signers
      max-width: 850px

    .sh-flow-panel-documents
      max-width: 950px

  @media (min-width: 1904px)
    .sh-flow-panel-signers
      max-width: 950px

  .other-pending-signers .v-list-item
    min-height: auto !important

  .other-pending-signers .v-list-group
    border: 1px solid $dark-grey
    border-radius: 3px

  .reminder-history-span
    display: inline-block
    width : 100%

  .reminder-history-signer-span
    white-space: nowrap
</style>

<style lang="sass" scoped>
  @media (max-width: 599px)
    button
      width: 100%

    .status-signer
      padding-bottom: 5px

    .status-info
      padding-top: 0
</style>
