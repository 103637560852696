<template>
  <div>
    <h1 class="text-center py-4">{{ $t( "signflow.ManageTags" ) }}</h1>
    <v-layout align-center justify-center>
      <v-container class="tags-pane d-flex flex-column">
        <div>
          <v-checkbox v-for="tag in Object.keys( allTags ).sort( ( a, b ) => a.localeCompare( b, undefined, { caseFirst: 'upper' } ) )" v-model="allTags[ tag ]" v-bind:key="tag"
                      :label="tag"
                      class="exist-tags" @change="updateChangeStatus"></v-checkbox>
          <div class="d-inline-flex new-tag-div">
            <v-checkbox
                v-model="newTagChecked"
                class="new-tag-checkbox"
                @change="addTag"
            ></v-checkbox>
            <v-text-field
                :label="$t( 'signflow.CreateNewTags' )"
                :hint="$t( 'signflow.CreateNewTagsHint' )"
                maxlength="20"
                v-model.trim="newTag"
                persistent-hint
                class="new-tag"
                @keyup.enter="addTagByEnterKey"
            ></v-text-field>
          </div>
        </div>
        <div class="d-inline-flex flex-column flex-md-row justify-space-between mt-12">
          <v-btn @click="onCancel" class="secondary-btn  mt-5 flex-grow-0 short-btn">{{
              $t( "signflow.Cancel" )
            }}
          </v-btn>
          <v-btn @click="onUpdate" class="primary-btn short-btn mt-5 flex-grow-0 short-btn" :disabled="!tagsChanged">
            {{ $t( "signflow.Confirm" ) }}
          </v-btn>
        </div>
      </v-container>
    </v-layout>
  </div>
</template>

<script>
export default {
    name : "tags-management-pane",
    props : {
        flow : Object,
        tags : Object
    },
    data()
    {
        return {
            updatedTags : {},
            newTagChecked : false,
            newTag : "",
            allTags : Object.assign( {}, this.tags ),
            tagsChanged : false,
            tagExists : false,
        };
    },
    computed : {
        validTag()
        {
            this.tagExists = false;
            if( this.newTag )
            {
                const lowerCaseTagsArr = Object.keys( this.allTags ).map( key => key.toLowerCase() );
                if( lowerCaseTagsArr.includes( this.newTag.toLowerCase() ) )
                {
                    this.tagExists = true;
                    return false;
                }
                else
                {
                    return true;
                }
            }
            else
            {
                return false;
            }
        }
    },
    methods : {
        /**
         * Cancel the tag management.
         */
        onCancel()
        {
            this.$emit( "close" );
            this._clearData();
        },
        /**
         * Update the tags.
         */
        onUpdate()
        {
            let data = [];
            for( const tag in this.allTags )
            {
                if( this.allTags[ tag ] )
                {
                    data.push( tag );
                }
            }
            this.$store.signFlowClient.updateSignFlowTags( this.flow.groupId, this.flow.uuid, data ).then( r =>
            {
                this.$emit( "close" );
                this._clearData();
            } ).catch( e => this.$error( this.$t( "signflow.Error!Unexpected" ) ) );
        },
        /**
         * Add new tag by clicking checkbox.
         */
        addTag()
        {
            if( this.newTagChecked && this.validTag )
            {
                this.tagsChanged = true;
                this.allTags[ this.newTag ] = true;
                this.newTag = "";
            }
            else if( this.tagExists )
            {
                this.$error( { text : this.$t( 'signflow.ErrorTagExists' ), snackbarTimeout : 2000 } );
            }
            setTimeout( () =>
            {
                this.newTagChecked = false;
            }, 10 );
        },
        /**
         * Add new tag by enter key.
         */
        addTagByEnterKey()
        {
            if( this.validTag )
            {
                this.tagsChanged = true;
                this.allTags[ this.newTag ] = true;
                this.newTag = "";
            }
            else if( this.tagExists )
            {
                this.$error( { text : this.$t( 'signflow.ErrorTagExists' ), snackbarTimeout : 2000 } );
            }
            setTimeout( () =>
            {
                this.newTagChecked = false;
            }, 10 );
        },
        /**
         * Update tag change status.
         */
        updateChangeStatus()
        {
            let changed = false;
            for( const key in this.allTags )
            {
                if( this.tags[ key ] )
                {
                    if( this.allTags[ key ] === false )
                    {
                        changed = true;
                    }
                }
                else
                {
                    if( this.allTags[ key ] === true )
                    {
                        changed = true;
                    }
                }
            }
            this.tagsChanged = changed;
        },
        /**
         * Clear the existing tag management data.
         */
        _clearData()
        {
            this.updatedTags = {};
            this.newTagChecked = false;
            this.newTag = "";
            this.allTags = Object.assign( {}, this.tags );
        }
    }
}
</script>

<style scoped>

</style>
