<template>
  <v-container class="maxWidth-1100 pt-2">
    <v-card flat class="my-2">
      <v-card-title class="pa-0">
        <h1 class="shio-section-title">{{ $t( "signedin.Sessions" ) }}</h1>
      </v-card-title>
      <v-responsive>
        <v-list>
          <v-list-item v-for="session in sessions" :key="session.issued_at_time">
            {{ session.issued_at_time }}
            ({{ $t( "signedin.SessionType!" + session.grant_type ) }})
          </v-list-item>
        </v-list>
      </v-responsive>
      <p class="text-left pl-4 mb-2">{{ $t( "signedin.LogOutAllDevicesMessage" ) }}</p>
      <v-card-actions class="devices-action">
        <v-btn class="primary-btn" @click="signOut()">{{ $t( "signedin.Logout" ) }}</v-btn>
      </v-card-actions>
    </v-card>
    <h1 class="shio-section-title">{{ $t( "signedin.Applications" ) }}</h1>
    <p v-if="apps.length === 0" class="text-left px-4 pt-4">
      {{ $t( "signedin.NoApplicationsMessage" ) }}
    </p>
    <v-responsive>
      <v-card class="d-flex flex-wrap" flat tile v-if="apps.length > 0">
        <v-card class="mt-4 session-app-card pa-0 ma-2" elevation="2" v-for="app in apps" :key="app.refresh_token">
          <v-row no-gutters align="center">
            <v-col class="sh-user-card-icon">
              <v-row align="center" justify="center" class="fill-height">
                <v-icon size="64" color="grey lighten-1" class="pa-4">mdi-star</v-icon>
              </v-row>
            </v-col>
            <v-col>
              <v-card flat light tile class="sh-integrations-user-card ma-auto pa-4">
                <v-container fill-height class="text-left">
                  <v-row>
                    <v-col cols="12" class="app-info py-0">
                      <div class="session-app-name font-weight-bold pb-1">{{ app.display_name }}</div>
                      <div class="session-app-info"><span>{{ $t( "signedin.Domain" ) }}:</span> {{ app.domain }}</div>
                      <div class="session-app-info"><span>{{ $t( "signedin.AccessGiven" ) }}:</span> {{ app.grant_time }}</div>
                      <div class="session-app-info"><span>{{ $t( "signedin.LastAccess" ) }}:</span> {{ app.last_access }}</div>
                      <div class="session-app-info"><span>{{ $t( "signedin.Device" ) }}:</span> {{ app.device }}</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-btn class="secondary-btn" @click="removeAccess(app.refresh_token)">
                        {{ $t( "signedin.RemoveAccess" ) }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-responsive>
  </v-container>
</template>

<script>
    import { format } from "date-fns";
    import util from "../../../util/util";
    import { globalEvents } from "@cloudlace/client";
    export default {
        name : "start-flow",
        components : {
        },
        data()
        {
            return {
                sessions : [],
                apps : []
            };
        },
        /**
         * Map this.items to state.notes through the Vuex mapState utility.
         */
        computed : {

        },
        methods : {
            /**
             * Emit signed-out event.
             */
            signOut()
            {
                util.logoutAllSessions( this.$store.shioCoreClient );
            },
            /**
             * Retrieve sessions and set property accordingly.
             */
            retrieveSessions()
            {
                this.$store.userSignedInClient.retrieveSessions().then( r => {
                    const ses = [];
                    for( let i = 0; i < r.sessions.length; i++ )
                    {
                        ses.push( {
                            grant_type : r.sessions[ i ].grant_type,
                            issued_at_time : format( new Date( r.sessions[ i ].grant_time ), this.$t( "signedin.DateTimeFormat" ) )
                        } );
                    }
                    this.sessions = ses;
                } ).catch( () => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            /**
             * Load authorised apps.
             *
             */
            retrieveAuthorizedApps()
            {
                this.$store.shioCoreClient.retrieveAppAccesses( util.getOrganizationId()).then( r => {
                    this.apps = r;
                    const apps = [];
                    for( let i = 0; i < r.length; i++ )
                    {
                        if( r[ i ].last_access )
                        {
                            r[ i ].last_access = format( new Date( r[ i ].last_access ), "dd.MM.yyyy, HH:mm" );
                        }
                        if( r[ i ].grant_time )
                        {
                            r[ i ].grant_time = format( new Date( r[ i ].grant_time ), "dd.MM.yyyy, HH:mm" );
                        }
                        apps.push( r[ i ] );
                    }
                    this.apps = apps;
                } ).catch( () => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            /**
             * Remove app access.
             */
            removeAccess( tokenId )
            {
                this.$store.shioCoreClient.deleteAppAccess( util.getOrganizationId(), tokenId ).then( r => {
                    this.retrieveSessions();
                    this.retrieveAuthorizedApps();
                } ).catch( () => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            }
        },
        /**
         * Retrieve sessions and authorized apps.
         */
        mounted()
        {
            this.retrieveSessions();
            this.retrieveAuthorizedApps();
        }
    }
</script>

<style lang="sass" scoped>
  @import ../../../styles/variables

  @media (max-width: 414px)
    .v-card__actions.devices-action
      padding-left: 10px
      padding-right: 10px

  @media (min-width: 415px)
    .v-card__actions.devices-action
      padding-left: 16px

  .session-app-card
    background-color: $light-grey
    max-width: 500px

  .session-app-name
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  .session-app-info
    font-size: 14px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    span
      color: $dark-grey

  @media (max-width: 1264px)
    .session-app-card
      max-width: 400px !important

  @media (max-width: 599px)
    button
      width: 100%
</style>
