<template>
  <v-card dark class="new-app-card pa-0" @click="$emit( 'click' )">
    <v-row no-gutters align="center">
      <v-col class="sh-user-card-icon">
        <v-row align="center" justify="center" class="fill-height">
          <v-icon size="64" color="white" class="pa-4">mdi-plus-circle-outline</v-icon>
        </v-row>
      </v-col>
      <v-col>
        <v-card flat light tile class="sh-integrations-user-card ma-auto pa-4">
          <v-container fill-height class="text-left">
            <v-row>
              <v-col cols="12" class="font-weight-bold">
                <p>{{ $t( 'client.IntegrateWithOAuth' ) }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn class="primary-btn" @click="$emit( 'click' )">{{ $t( 'client.AddOAuthApplication' ) }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
    import util from "../../../util/util";

    export default {
        data()
        {
            return {};
        },
        computed :
        {},
        methods :
        {}
    }
</script>

<style lang="sass" scoped>
  @import "../../../styles/style"

  .new-app-card
    background-color: $card-grey
    p
      font-size: 20px

  @media (max-width: 599px)
    button
      width: 100%
</style>
