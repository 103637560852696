<template>
  <v-container class="pt-4 maxWidth-1100">
    <v-overlay v-if="counterStatus === 'PENDING' || signatureCounterStatus === 'PENDING'" class="no-active-subscription-overlay">
      <p class="font-weight-bold no-active-subscription-notice">{{ $t( "signedin.CountersPending" ) }}</p>
    </v-overlay>
    <h1 class="shio-section-title">{{ $t( "signedin.Statistics" ) }}</h1>
    <v-responsive class="pt-5 px-4">
        <v-tabs>
          <v-tab class="tab-title">{{ $t( "signedin.UserActivity" ) }}</v-tab>
          <v-tab class="tab-title" v-if="advanced_on">{{ $t( "signedin.Signatures" ) }}</v-tab>
          <v-tab-item class="text-left">
            <user-activity-stats ref="userActivityStatsRef" class="elevation-1 mt-5" optional_properties=""></user-activity-stats>
          </v-tab-item>
          <v-tab-item class="text-left">
            <signature-stats ref="signatureStatsRef" class="elevation-1 mt-5" optional_properties=""></signature-stats>
          </v-tab-item>
        </v-tabs>
      </v-responsive>
  </v-container>
</template>

<script>
    import UserActivityStats from "./UserActivityStats";
    import SignatureStats from "./SignatureStats";
    import LineChart from "@cloudlace/santra-ui/src/components/common/LineChart";
    import util from "../../../util/util";
    export default {
        name : "StatisticsPane",
        components : {
            "user-activity-stats" : UserActivityStats,
            "signature-stats" : SignatureStats,
            LineChart
        },
        data()
        {
            return {
                counterStatus : "",
                signatureCounterStatus : "",
                advanced_on: $cc_user_info.organization.enabled_signature_types.length,
            }
        },
        mounted()
        {
            Promise.all( [ this.$store.signFlowClient.retrieveCounterStatus( util.getOrganizationId() ),
                           this.$store.signFlowClient.retrieveSignatureCounterStatus( util.getOrganizationId() )] ).then( r =>
            {
                this.counterStatus = r[ 0 ].status;
                this.signatureCounterStatus = r[ 1 ].status;
                if( this.counterStatus === "READY" && this.signatureCounterStatus === "READY" )
                {
                    this.$refs.userActivityStatsRef.retrieveUsers();
                }
            } ).catch( e => this.$error( this.$t( "message.Error!Unexpected" ) ) );
        }
    }
</script>
