<template>
  <div v-if="started">
    <div>
      <p class="cvw-email" v-if="!tokenCheckFailed">
        <v-text-field type="email"
                      :disabled="inProgress"
                      v-model="email"
                      @focus="clearMessage"
                      @keydown="checkSubmit"
                      :placeholder="labels[ 'LoginEmail' ]"
                      :rules="rules.email"
                      @input="validateEmail"
        ></v-text-field>
      </p>
      <p v-if="mode === 'login' && login_type !== 'pwless'" class="cvw-password">
        <v-text-field :disabled="inProgress"
                      type="password"
                      v-model="password"
                      @focus="clearMessage"
                      @keydown="checkSubmit"
                      :placeholder="labels[ 'Password' ]"
                      id="password"
        >
          <v-icon
              v-show="showCapslock"
              slot="append"
              tabindex="-1"
              id="capsLock"
          >
            mdi-keyboard-caps
          </v-icon>
          <v-icon
              v-if="!show"
              slot="append"
              tabindex="-1"
              @click="toggleCloak()"
              id="showPassword"
          >
            mdi-eye
          </v-icon>
          <v-icon
              v-if="show"
              slot="append"
              tabindex="-1"
              @click="toggleCloak()"
          >
            mdi-eye-off
          </v-icon>
        </v-text-field>
      </p>
      <div v-if="( mode === 'resetpw' || mode === 'confirm' ) && !tokenCheckFailed ">
        <p class="cvw-set-password">
          <signhero-set-password-field
              :disabled="inProgress"
              v-model="new_password"
              :messages="messages || {}"
              @focus="clearMessage"
              @keydown="checkSubmit"
              :rules="[isPasswordValid]"
              @input="validateNewPassword"
          >
          </signhero-set-password-field>
        </p>
        <p class="password-requirement">
          {{ $t( "message.PasswordRequirement" ) }}
        </p>
        <p class="cvw-set-password">
          <signhero-set-password-field
              :disabled="!isEmailValid || isEmailValid.length > 0 || !isPasswordValid || isPasswordValid.length > 0 || inProgress"
              v-model="repeat_password"
              @focus="clearMessage"
              @keydown="checkSubmit"
              :messages="messages || {}"
              :placeholder="labels[ 'RepeatPassword' ]"
              :rules="[isRepeatPasswordValid]"
              @input="validateRepeatPassword"
          ></signhero-set-password-field>
        </p>
      </div>
      <div class="pt-15 pb-15" v-if="( mode === 'resetpw' || mode === 'confirm' ) && tokenCheckFailed" v-html="labels[ 'Error!TokenCheckFailed' ]">
      </div>
    </div>
    <div class="control-node">
      <div>
        <p v-if="mode === 'login' && login_type !== 'pwless'" class="cvw-change-password">
          <a v-if="!mailInProgress" @click="requestChangePassword">{{ labels[ "ForgotYourPassword" ] }}</a>
          <span v-if="mailInProgress">...</span>
        </p>
        <p v-if="status === 'confirm-failed'" class="cvw-request-confirm">
          <v-btn v-if="!mailInProgress" color="primary" @click="requestConfirmToken">
            {{ labels[ "RequestConfirmToken" ] }}
          </v-btn>
          <span v-if="mailInProgress">...</span>
        </p>
        <p v-if="statusMessage" class="cvw-status-message"> {{ statusMessage }}</p>
      </div>
      <div>
        <v-btn v-if="mode === 'login'" class="cvw-sign-in-btn" color="primary" :disabled="!valid || inProgress"
               @click="signIn">
          {{ labels[ "Action!SignIn" ] }}
        </v-btn>
        <v-tooltip bottom :disabled="valid" v-if="mode === 'resetpw' && !tokenCheckFailed">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn
                  class="cvw-set-password-btn" color="primary"
                  :disabled="!valid || inProgress"
                  @click="setPassword"
              >
                {{ labels[ "Action!SetPassword" ] }}
              </v-btn>
            </div>
          </template>
          <span>{{ labels[ "TooltipSetPassword" ] }}</span>
        </v-tooltip>
        <v-tooltip bottom :disabled="valid" v-if="mode === 'confirm' && status !== 'confirm-failed' && !tokenCheckFailed">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn v-bind="attrs"
                     class="cvw-confirm-account-btn" color="primary" :disabled="!valid || inProgress"
                     @click="setPassword">
                {{ labels[ "Action!ConfirmAccount" ] }}
              </v-btn>
            </div>
          </template>
          <span>{{ labels[ "TooltipSetPassword" ] }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
    import {globalEvents, JSONPureClient} from "@cloudlace/client";
    import serviceSchema from "../../../node_modules/@cloudlace/vue-widgets/src/api/signedout-service-schema";
    import signupSchema from "../../../node_modules/@cloudlace/vue-widgets/src/api/signup-service-schema";
    import emailRegex from "../../../node_modules/@cloudlace/vue-widgets/src/api/email-regex";
    import tester from "owasp-password-strength-test";
    import SignHeroSetPasswordField from "../../components/signedout/SignHeroSetPasswordField";

    const signInClient = new JSONPureClient( {
        service_url : process.env.VUE_APP_USER_SIGNEDOUT_URL,
        service_map : serviceSchema
    } );
    const confirmClient = new JSONPureClient( {
        service_url : process.env.VUE_APP_SERVICE_SIGNUP_URL,
        service_map : signupSchema
    } );

    export default {
        name : "signhero-login-control",
        props : {
            scope : String,
            redirect_url : String,
            type : String,
            messages : { type : Object, default : () => {} },
        },
        data()
        {
            const labels = {};
            const msgs = this.messages || {};
            const keys = [ "LoginEmail",
                           "Password",
                           "RepeatPassword",
                           "ForgotYourPassword",
                           "RequestConfirmToken",
                           "Action!SignIn",
                           "Action!SetPassword",
                           "Action!ConfirmAccount",
                           "TooltipSetPassword",
                           "NewPassword",
                           "EnterYourEmail",
                           "Error!InvalidEmail",
                           "AuthCodeCreated",
                           "Error!InvalidCredentials",
                           "Error!TemporarilyLockedOut",
                           "Error!InvalidPasswordReset",
                           "Error!Unexpected",
                           "Error!InvalidScopes",
                           "Error!TokenCheckFailed",
                           "SentPasswordResetRequest",
                           "SentConfirmTokenRequest" ];
            keys.forEach( key =>
            {
                labels[ key ] = msgs[ key ] || this.$t( 'message.' + key );
            } );
            return {
                placeholder : labels.NewPassword,
                email : "",
                password : "",
                new_password : "",
                repeat_password : "",
                statusMessage : "",
                mode : "login",
                inProgress : false,
                mailInProgress : false,
                started : false,
                tokenCheckFailed : false,
                status : "",
                login_type : "password",
                labels : labels,
                rules : {
                    email: [
                        v => !!v || this.$t( 'message.EmailRequired' ),
                        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( v.trim() ) || this.$t( 'message.EmailMustValid' ),
                    ],
                },
                show : false,
                showCapslock : "",
                isPasswordValid : "",
                isRepeatPasswordValid : "",
                isEmailValid : ""
            }
        },
        components : {
            "signhero-set-password-field" : SignHeroSetPasswordField
        },
        computed : {
            valid()
            {
                if( this.mode === "resetpw" || this.mode === "confirm" )
                {
                    return !!this._email
                           && !!this.new_password
                           && !!this.scope
                           && !this.inProgress
                           && this.repeat_password === this.new_password;
                }
                else if( this.login_type === "pwless" )
                {
                    return !!this._email && !!this.scope;
                }
                else
                {
                    return !!this._email && !!this.password && !!this.scope && !this.inProgress;
                }
            },
            _email()
            {
                return this.email && emailRegex.test( this.email ) ? this.email.trim() : "";
            },
        },
        created()
        {
            const token = this.$router.currentRoute.query.token;
            if( this.$router.currentRoute.path === '/resetpw' )
            {
                signInClient.retrieveTokenCheck( token, 'resetpw' ).then( () =>
                {
                    this.mode = "resetpw";
                    this.started = true;
                } ).catch( err =>
                {
                    this.tokenCheckFailed = ( err.code_str === "Failed" );
                    this.mode = "resetpw";
                    this.started = true;
                } );
            }
            else if( this.$router.currentRoute.path === '/confirm' )
            {
                signInClient.retrieveTokenCheck( token, 'confirmacct' ).then( () =>
                {
                    this.mode = "confirm";
                    this.started = true;
                } ).catch( err =>
                {
                    this.tokenCheckFailed = ( err.code_str === "Failed" );
                    this.mode = "confirm";
                    this.started = true;
                } );
            }
            else if( this.$router.currentRoute.path === '/pwless' )
            {
                this.login_type = "pwless";
                this.pwlessSignIn();
            }
            else
            {
                this.login_type = this.type || "password";
                this.started = true;
            }
        },
        methods : {
            validateEmail (value)
            {
                value = value.replace( /[\u200B-\u200F\uFEFF]/g, '' ).trim();
                this.email = value;
                if( value && emailRegex.test( value ) ? value.trim() : "" )
                {
                    return this.isEmailValid = true;
                }
                else
                {
                    return this.isEmailValid = this.$t( 'message.EmailMustValid' );
                }
            },
            validateNewPassword(value)
            {
                this.new_password = value;
                if( tester.test( value ).strong )
                {
                    return this.isPasswordValid = true;
                }
                else
                {
                    return this.isPasswordValid = this.$t( 'message.PasswordMustValid' );
                }
            },
            validateRepeatPassword(value)
            {
                if( value === this.new_password )
                {
                    return this.isRepeatPasswordValid = true;
                }
                else
                {
                    return this.isRepeatPasswordValid = this.$t( 'message.RepeatPasswordMustSame' );
                }
            },
            setPassword()
            {
                const token = this.$router.currentRoute.query.token;
                if( !token )
                {
                    this.statusMessage = this.labels[ 'Error!Unexpected' ];
                    this.mode = "login";
                }
                else
                {
                    this.inProgress = true;
                    if( this.mode === 'resetpw' )
                    {
                        this._resetPassword( token );
                    }
                    else if( this.mode === 'confirm' )
                    {
                        this._confirmAccount( token );
                    }
                    else
                    {
                        this.statusMessage = this.labels[ 'Error!Unexpected' ];
                    }
                }
            },
            checkSubmit( evt )
            {
                if( evt.code === "Enter" && this.valid )
                {
                    if( this.mode === 'resetpw' || this.mode === 'confirm' )
                    {
                        this.setPassword();
                    }
                    else
                    {
                        this.signIn();
                    }
                }
            },
            clearMessage()
            {
                this.statusMessage = "";
            },
            requestChangePassword()
            {
                if( !this._email )
                {
                    this.$prompt( {
                        text : this.$t( "message.EnterYourEmail" ),
                        cancelText : this.$t( "message.Cancel" ),
                        acceptText : this.$t( "message.Confirm" ),
                    } ).then(
                    result =>
                    {
                        result = result.trim();
                        if( emailRegex.test( result ) )
                        {
                            this._requestChangePassword( result )
                        }
                        else
                        {
                            this.$warn( this.labels[ 'Error!InvalidEmail' ] );
                        }
                    } ).catch( err =>
                    {
                    } );
                }
                else
                {
                    this._requestChangePassword( this._email );
                }
            },
            requestConfirmToken()
            {
                if( !this._email )
                {
                    this.$prompt( this.labels[ 'LoginEmail' ] ).then(
                    result =>
                    {
                        result = result.trim();
                        if( emailRegex.test( result ) )
                        {
                            this._requestConfirmToken( result )
                        }
                        else
                        {
                            this.$warn( this.labels[ 'Error!InvalidEmail' ] );
                        }
                    } ).catch( err =>
                    {
                    } );
                }
                else
                {
                    this._requestConfirmToken( this._email );
                }
            },
            pwlessSignIn()
            {
                const token = this.$router.currentRoute.query.token;
                if( token )
                {
                    signInClient.createSessionWithCode( token ).then(
                    result => this._handleSessionResult( result ) ).catch( err =>
                    {
                        this.started = true;
                    } );
                }
                else
                {
                    this.started = true;
                    // this.statusMessage = this.labels[ "Error!InvalidCredentials" ];
                }
            },
            signIn()
            {
                this.inProgress = true;
                if( this.login_type === "pwless" )
                {
                    signInClient.createAuthCode( this.scope, this._email ).then( result =>
                    {
                        this.inProgress = false;
                        this.statusMessage = this.labels[ "AuthCodeCreated" ];
                    } ).catch( err =>
                    {
                        this.statusMessage = this.labels[ "Error!Unexpected" ];
                    } );
                }
                else
                {
                    this.password = this.password.replace( /[\u200B-\u200F\uFEFF]/g, '' );
                    signInClient.createSessionWithPassword( this.scope, this._email, this.password ).then(
                    result => this._handleSessionResult( result ) ).catch( err =>
                    {
                        this.inProgress = false;
                        this.mode = "login";
                        if( err.code_key === 401 )
                        {
                            this.statusMessage = this.labels[ "Error!InvalidCredentials" ];
                        }
                        else if( err.code_key === 403 )
                        {
                            const minutes = ""
                                            + Math.ceil( parseInt( err.user_msg.substring( err.user_msg.indexOf( ':' )
                                                                                           + 2 ) ) / 60 );
                            this.statusMessage =
                            this.labels[ "Error!TemporarilyLockedOut" ].replace( '${minutes}', minutes );
                        }
                        else
                        {
                            this.statusMessage = this.labels[ "Error!Unexpected" ];
                        }
                    } );
                }
            },
            _confirmAccount( token )
            {
                confirmClient.createConfirmedUser( token, this._email, this.new_password, this.scope ).then( result =>
                {
                    this.password = this.new_password;
                    this.signIn();
                } ).catch( err =>
                {
                    if( err.code_key === 401 )
                    {
                        this.statusMessage = this.labels[ 'Error!InvalidPasswordReset' ];
                    }
                    else
                    {
                        this.statusMessage = this.labels[ 'Error!Unexpected' ];
                    }
                    this.status = "confirm-failed";
                    this.inProgress = false;
                } );
            },
            _resetPassword( token )
            {
                signInClient.updatePasswordWithToken( token, this._email, this.new_password, this.scope ).then( result =>
                {
                    this.password = this.new_password;
                    this.signIn();
                } ).catch( err =>
                {
                    if( err.code_key === 401 )
                    {
                        this.statusMessage = this.labels[ 'Error!InvalidPasswordReset' ];
                        this.mode = "login";
                    }
                    else
                    {
                        this.statusMessage = this.labels[ 'Error!Unexpected' ];
                    }
                    this.inProgress = false;
                } );
            },
            _handleSessionResult( result )
            {
                if( result.scope.length === 0 )
                {
                    this.statusMessage = this.labels[ "Error!InvalidScopes" ];
                    globalEvents.emit( "signed-out", {} );
                    this.inProgress = false;
                    this.mode = "login";
                }
                else if( this.redirect_url )
                {
                    window.location.assign( this.redirect_url );
                }
                else
                {
                    window.$cc_user_info = result;
                    globalEvents.emit( "signed-in", result );
                }
            },
            _requestChangePassword( email )
            {
                this.mailInProgress = true;
                signInClient.createPasswordUpdateToken( email, this.scope ).then( result =>
                {
                    this.mailInProgress = false;
                    this.$alert( {
                        text : this.$t( 'message.SignHeroSentPasswordResetRequest' ),
                        acceptText : this.$t( "signflow.Ok" )
                    } );
                } ).catch( err =>
                {
                    console.error( err );
                    this.mailInProgress = false;
                    this.statusMessage = this.labels[ 'Error!Unexpected' ];
                } );
            },
            _requestConfirmToken( email )
            {
                this.mailInProgress = true;
                confirmClient.createConfirmToken( email, this.scope ).then( result =>
                {
                    this.mailInProgress = false;
                    this.statusMessage = this.$t( 'message.SignHeroSentConfirmTokenRequest' );
                } ).catch( err =>
                {
                    console.error( err );
                    this.mailInProgress = false;
                    this.statusMessage = this.labels[ 'Error!Unexpected' ];
                } );
            },
            toggleCloak()
            {
                this.show = !this.show;
                this.cloakPassword();
            },
            cloakPassword()
            {
                const showPassword = document.querySelector( "#show-password" );
                const passwordField = document.querySelector( "#password" );
                const type = passwordField.getAttribute( "type" ) === "password" ? "text" : "password";
                passwordField.setAttribute( "type", type );
            },
            verifyCapsLock()
            {
                document.addEventListener("DOMContentLoaded", function () {
                  const capsLock = document.getElementById('capsLock');
                    document.addEventListener('keyup', (e) => {
                        if (e.getModifierState('CapsLock'))
                        {
                            capsLock.style.display = 'block';
                        }
                        else {
                            capsLock.style.display = 'none';
                        }
                    });
                    document.addEventListener('keydown', (e) => {
                        if (e.getModifierState('CapsLock'))
                        {
                            capsLock.style.display = 'block';
                        }
                        else {
                            capsLock.style.display = 'none';
                        }
                    });
                });
            }
        },
        mounted() {
            this.verifyCapsLock();
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../styles/variables"

  .control-node p
    font-size: 18px

  .control-node .v-btn
    width: 100%

  .password-requirement
    font-size: 14px
    color: $dark-grey
</style>
