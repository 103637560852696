<template>
  <v-container fluid class="fill-height pa-0">
    <v-row no-gutters class="fill-height">
      <v-col align="center" class="pa-6 ma-auto">
        <div class="sign-in-up ma-auto text-left pt-12 pt-sm-0 pt-md-0 pt-lg-0 pt-xl-0">
          <h1 class="welcome-message pb-1 font-weight-regular" v-html="welcomeMessage"></h1>
          <p class="pb-3">{{ $t( 'message.WelcomeText') }}</p>
          <signhero-login-control scope="shio.*" :messages="messages"/>
        </div>
        <div class="hidden-md-and-up no-account-small-screen pt-8">
          <v-divider class="pb-10"></v-divider>
          <h1 class="welcome-message font-weight-regular text-center pb-3"
              v-html="$t( 'message.DontHaveSignHeroAccount' )"></h1>
          <p>
            <v-btn class="primary-btn" to="/signup">{{ $t( "message.GetStarted" ) }}</v-btn>
          </p>
        </div>
      </v-col>
      <v-col class="dark-image-bg pa-6 hidden-sm-and-down">
        <v-row align="center" class="fill-height">
          <v-flex>
            <h1 class="welcome-message font-weight-regular text-center my-3"
                v-html="$t( 'message.DontHaveSignHeroAccount' )"></h1>
            <p class="pt-3">
              <v-btn class="primary-btn" to="/signup">{{ $t( "message.GetStarted" ) }}</v-btn>
            </p>
          </v-flex>
        </v-row>
      </v-col>
    </v-row>
    <sh-app-footer class="pb-0"></sh-app-footer>
  </v-container>
</template>

<script>
    import AppFooter from "../../components/common/AppFooter";
    import SignHeroLoginControl from "../../components/signedout/SignHeroLoginControl";

    export default {
        name : "SignInPane.vue",
        data()
        {
            return {
                messages : {
                    "ForgotYourPassword" : this.$t( 'message.IForgotMyPassword' ),
                    "Action!SignIn" : this.$t( 'message.SignIn' ),
                    "Error!TokenCheckFailed" : this.$t( 'message.ResetPWError'),
                },
                welcomeMessage: this.$router.currentRoute.name
                === 'resetpw' ? this.$t('message.ResetPasswordTitle') :
                      this.$router.currentRoute.name
                      === 'confirm' ? this.$t('message.ConfirmAccountTitle') : this.$t(
                              'message.WelcomeMessage'),
            }
        },
        components : {
            "sh-app-footer" : AppFooter,
            "signhero-login-control" : SignHeroLoginControl
        },
    }
</script>

<style lang="sass">
  @import "../../styles/style"

  .cvw-change-password a
    color: $text-black !important
    font-size: 14px
    text-decoration: underline

  .cvw-change-password a:hover
    color: $vivid-pink !important
    font-size: 14px
    text-decoration: underline

  .cvw-change-password
    text-align: right

  .control-node
    text-align: center !important

  .no-account-small-screen
    max-width: 450px

  .cvw-status-message
    font-size: 14px !important
    padding: 10px
    box-sizing: border-box
    background-color: $light-grey
    cursor: pointer
    color: $text-black
    margin-top: 15px

  .cvw-sign-in-btn.v-btn.theme--light.v-size--default,
  .cvw-confirm-account-btn.v-btn.theme--light.v-size--default,
  .cvw-set-password-btn
    background-color: $vivid-pink !important
    border-color: $vivid-pink !important
    min-width: 200px !important
    width: auto !important
    height: 40px !important
    font-size: 16px !important
    box-shadow: none

  .cvw-sign-in-btn.v-btn--disabled.theme--light,
  .cvw-confirm-account-btn.v-btn--disabled.theme--light,
  .cvw-set-password-btn.v-btn.v-btn--disabled.v-btn--has-bg
    background-color: $vivid-pink !important
    opacity: 0.5
    color: white !important
    height: 40px
    font-size: 16px
    text-transform: none

  .cvw-sign-in-btn.v-btn.theme--light:hover
    background-color: $dark-pink !important
</style>
