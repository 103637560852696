import ApiRootCard from "../../components/common/ApiRootCard";

export default [
    {
        path : '/',
        name : 'api-root',
        component : ApiRootCard
    },
    {
        path : '/*',
        redirect : '/'
    }
]