<template>
  <div class="ma-auto mt-4 maxWidth-1100">
    <p class="text-left">{{ $t( "signedin.OrganisationHas" ) }} {{activeUsersCount}} {{ $t( "signedin.ActiveUsers" ) }}
      {{inactiveUsersCount}} {{ $t( "signedin.InactiveUsers" ) }}</p>
    <p class="text-left">
      {{ $t( "signedin.AmountOfDocuments" ) }} {{documentCount}}
      {{ $t( "signedin.AmountOfSignatureProcesses" ) }} {{processCount}}.</p>
    <v-row class="mt-2">
      <v-col cols="12" sm="12" md="9" lg="9" class="pb-1">
        <v-autocomplete
            v-model="selected_users"
            :items="users"
            :item-text="item => `${item.name}  (${item.email}) ${!item.active || item.disabled? ' | ' + $t( 'signedin.Inactive' ):''}`"
            :item-value="item => `${item.sub}`"
            :item-disabled="item => false"
            dense
            chips
            small-chips
            return-object
            :label="$t( 'signedin.SelectUsers' )"
            @change="selectUsers"
            multiple
            v-on:change="whole_organisation = null"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" class="pb-1 radio-padding">
        <v-radio-group v-model="whole_organisation">
          <v-radio class="whole-organisation" :label="$t( 'signedin.WholeOrganisation' )" value="all"
                   @click="loadChart( true )" v-on:change="selected_users = null"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <div>
      <v-row>
        <v-col cols="6">
          <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="formatFromDate"
                  :label="$t( 'signedin.From' )"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                class="from-date"
                v-model="from_date"
                :locale="fullLocale"
                :first-day-of-week="1"
                @input="loadChart()"
                :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="formatToDate"
                  :label="$t( 'signedin.To' )"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                class="to-date"
                v-model="to_date"
                :locale="fullLocale"
                :first-day-of-week="1"
                @input="loadChart()"
                :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <p class="text-left mt-5">{{ chartDescription }}</p>
    <div class="mt-6">
      <line-chart ref="chart" v-if="chart.data.chartdata" :options="chart.data.options" :chartdata="chart.data.chartdata"/>
    </div>
    <div>
      <v-row justify="center">
        <v-col cols="12">
          <p class="mt-10 font-weight-bold mb-3 text-left table-header">{{ $t( "signedin.UserActivity" ) }}</p>
          <v-data-table
              class="user-activity-table"
              dense
              disable-filtering
              disable-pagination
              fixed-header
              height=352
              :headers="[ {
                  text: $t( 'signedin.User' ),
                  value: 'name',
                }, {
                  text: $t( 'signedin.Email' ),
                  value: 'email'
                }, {
                  text: $t( 'signedin.SignatureProcesses' ),
                  align: 'center',
                  value: 'processPeriodCount'
                }, {
                  text: $t( 'signedin.AllSignatureProcesses' ),
                  align: 'center',
                  value: 'processCurrentCount'
                } ]"
              hide-default-footer
              :items="userCounters"
              :item-class="itemRowFontColor">
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
    import LineChart from "@cloudlace/santra-ui/src/components/common/LineChart";
    import chartUtil from "@cloudlace/santra-ui/src/components/common/chart-util";
    import Vue from "vue";
    import util from "../../../util/util";
    import statsMixin from "./stats-mixin";
    export default {
        name : "UserActivityStats",
        components : {
            LineChart
        },
        props : {
            counterStatus : String
        },
        mixins : [ statsMixin ],
        data()
        {
            const from = Date.now() - (30 * 24 * 3600 * 1000);
            const to = Date.now();
            return {
                selected_users : [],
                users : [],
                userCounters : [],
                activeUsersCount : 0,
                inactiveUsersCount : 0,
                documentCount : 0,
                processCount : 0,
                userCount : 0,
                curLocale : util.getLocale(),
                chartDescription : this.$t( "signedin.ChartDescriptionOrg" ),
                whole_organisation : "all",
                from_date : new Date( from ).toISOString().substring( 0, 10 ),
                to_date : new Date( to ).toISOString().substring( 0, 10 ),
                date_format : "dd.MM.yyyy",
                time_format : "dd.MM, HH:mm",
                from_menu : false,
                to_menu : false,
                color : 0,
                colors : [ "#f44336",
                           "#2196f3",
                           "#ff9800",
                           "#009688",
                           "#e91e63",
                           "#03a9f4",
                           "#ff5722",
                           "#4caf50",
                           "#795548",
                           "#3f51b5",
                           "#8bc34a",
                           "#607d8b",
                           "#9c27b0",
                           "#00bcd4" ],
                chart : {
                    name : "signhero-stats",
                    title : "Filters",
                    props : {
                        resolution : 'day',
                        span : 30 * 24 * 3600 * 1000,
                        agg : "max"
                    },
                    data : {
                        options : {
                            responsive : true,
                            maintainAspectRatio : false,
                            scales : {
                                yAxes : [ {
                                    ticks : {
                                        suggestedMax: 1, // Hides -1 when all zeros
                                        precision : 0 // Only integers
                                    }
                                } ]
                            },
                            animation : {
                                duration : 0
                            }
                        }
                    },
                    sources : []
                }
            }
        },
        methods : {
            loadChart( skipSummary )
            {
                return new Promise( ( resolve, reject ) =>
                {
                    this.chartDescription = this.selected_users && this.selected_users.length ?
                    this.$t( "signedin.ChartDescriptionUser" ) : this.$t( "signedin.ChartDescriptionOrg" );
                    const groupId = util.getOrganizationId();
                    const statsFrom = new Date( this.from ).toISOString();
                    const statsTo = new Date( this.to ).toISOString();
                    if( !skipSummary )
                    {
                        this._showActivitySummary( groupId, statsFrom, statsTo );
                    }
                    const sources = this._getSources();
                    this.chart.sources = sources;
                    Promise.all( sources.map( source =>
                    {
                        return this.$store.signFlowClient.retrieveCounter( groupId, source.counter, {
                            resolution : this.resolution,
                            statsFrom : statsFrom,
                            statsTo : statsTo,
                            agg : this.chart.props.agg,
                            userId : source.userId
                        } );
                    } ) ).then( r =>
                    {
                        if( this.chart.sources !== sources )
                        {
                            // chart.sources has changed; ignore this response.
                            return resolve();
                        }
                        let datasets = [];
                        let series;
                        for( let i = 0; i < sources.length; i++ )
                        {
                            const data = r[ i ];
                            const source = sources[ i ];
                            if( data && source.display )
                            {
                                series = data.counter_values;
                                datasets.push( {
                                    ...source,
                                    data : series.map( point => point.value )
                                } );
                                this._calculatePeriodCount( source, series );
                            }
                        }
                        datasets.sort( ( a, b ) =>
                        {
                            return Math.max.apply( Math, b.data ) - Math.max.apply( Math, a.data )
                        } );
                        this.assignColors( datasets, this.chart );
                        Vue.set( this.chart.data, "chartdata",
                        {
                            labels : chartUtil.getLabels( this, series ),
                            datasets : datasets
                        } );
                        if( this.$refs.chart )
                        {
                            // since reactivity is 'not robust', update the chart manually
                            this.$refs.chart.renderChart( this.chart.data.chartdata, this.chart.data.options );
                        }
                        resolve();
                    } ).catch( e =>
                    {
                        this.$error( this.$t( 'message.Error!Unexpected' ) );
                        reject( e );
                    } );
                } );
            },
            _getSources()
            {
                if( this.selected_users && this.selected_users.length )
                {
                    const sources = [];
                    for( let i = 0; i < this.selected_users.length; i++ )
                    {
                        const user = this.selected_users[ i ];
                        sources.push( {
                            display : true,
                            counter : "sign-flows",
                            userId : user.sub,
                            label : user.name,
                            fill : false,
                            lineTension : 0
                        } );
                    }
                    return sources;
                }
                else
                {
                    return [ {
                        display : true,
                        counter : "sign-flows",
                        label : this.$t( "signedin.Processes" ),
                        fill : false,
                        lineTension : 0
                    }, {
                        display : true,
                        counter : "documents",
                        label : this.$t( "signedin.Documents" ),
                        fill : false,
                        lineTension : 0
                    } ];
                }
            },
            _calculatePeriodCount( source, series )
            {
                if( !source.userId )
                {
                    const count = series[ series.length - 1 ].value - series[ 0 ].value;
                    switch( source.counter )
                    {
                        case "sign-flows":
                            this.processCount = count;
                            break;
                        case "documents":
                            this.documentCount = count;
                            break;
                        case "signers":
                            this.userCount = count;
                    }
                }
            },
            _showActivitySummary( groupId, statsFrom, statsTo )
            {
                this.$store.signFlowClient.retrieveUserCounterSummary(  groupId, statsFrom, statsTo ).then( r =>
                {
                    this.userCounters = r.userCounters;
                } ).catch( e => this.$error( this.$t( "message.Error!Unexpected" ) ) );
            },
            retrieveUsers()
            {
                this.$store.shioCoreClient.retrieveUsers( util.getOrganizationId() ).then( r =>
                {
                    this.users = r.entities;
                    this.activeUsersCount = this.users.filter( ( obj ) => obj.active === true && obj.disabled !== true ).length;
                    this.inactiveUsersCount = this.users.length - this.activeUsersCount;
                    this.users.sort(( a , b ) => a.name.localeCompare( b.name, this.curLocale  ) );
                    this.loadChart();
                } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            }
        },
        mounted()
        {
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../../styles/style"

  .from-date.v-card, .to-date.v-card
    margin: 0 !important

  .v-data-table.user-activity-table
    border: 0

  .table-header
    font-size: 20px

  @media(min-width: 1265px)
    .radio-padding
      padding-left: 75px

  @media(max-width: 960px)
    .radio-padding
      padding-top: 0
      padding-bottom: 15px !important
</style>
