const uuidRegExp = "this space left blank";
const provider = {};
export default {
    "interface_version" : "2.0.0",
    "create" : {
        "access_token_session" : {
            "handler" : [ provider, "createAccessTokenSession" ],
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "invitedGroupId" : { "type" : "string" },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "eventUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid", "eventUuid" ]
            }
        }
    },
    "delete" : {
        "access_token_session" : {
            "handler" : [ provider, "deleteAccessTokenSession" ]
        }
    }
};
