<template>
  <v-container fluid class="fill-height pa-0">
    <v-row no-gutters class="fill-height">
      <v-col class="dark pa-6 hidden-sm-and-down">
        <v-row align="center" class="fill-height">
          <v-flex>
            <h1 class="welcome-message text-center" v-html="$t( 'message.AlreadyHaveSignHeroAccount' )"></h1>
            <p>
              <v-btn to="/signin">{{ $t( "message.SignInHere" ) }}</v-btn>
            </p>
          </v-flex>
        </v-row>
      </v-col>
      <v-col class="light pa-6">
        <v-form v-model="formValid">
          <h2 class="text-center" v-html="$t( 'message.ConfirmAccountTitle' )"></h2>
          <p>{{ $t( "message.WelcomeToSignHero" ) }}</p>
          <div>
            <v-text-field type="email" ref="email" v-model="email" :label="$t( 'message.Email' )"
                          :disabled="disabled" @keydown="checkSubmit"></v-text-field>
          </div>
        </v-form>
        <div class="terms-disclaimer" v-html="$t( 'message.AcceptTermsOfUseAndPrivacyPolicy' )">
        </div>
        <div>
          <div>
            <v-btn @click="confirmAccount()" :disabled="!valid" class="primary-btn">{{ $t( 'message.ConfirmAccount' ) }}
            </v-btn>
          </div>
          <p>{{ message }}</p>
        </div>
        <div class="hidden-md-and-up pa-3">
          <h3 class="welcome-message text-center" v-html="$t( 'message.AlreadyHaveSignHeroAccount' )"></h3>
          <p>
            <v-btn to="/signin">{{ $t( "message.SignInHere" ) }}</v-btn>
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import {JSONPureClient} from "@cloudlace/client";
    import userSignedOutServiceMap from "../../api/user-signed-out-service-map";
    import util from "../../util/util";

    const confirmClient = new JSONPureClient( {
        service_url : process.env.VUE_APP_USER_SIGNEDOUT_URL,
        service_map : userSignedOutServiceMap
    } );
    export default {
        name : "signup-pane",
        data()
        {
            return {
                formValid : false,
                email : "",
                message : "",
                disabled : false
            }
        },
        computed : {
            valid()
            {
                return this.formValid && this.email;
            }
        },
        methods : {
            /**
             * Email validator.
             */
            validateEmail : util.validateEmail,
            /**
             * Confirm account.
             */
            confirmAccount()
            {
                this.disabled = true;
                confirmClient.createPasswordUpdateToken( this.email, "shio.*" ).then( res =>
                {
                    this.message = this.$t( "message.ConfirmAccountInfo" );
                } ).catch( err =>
                {
                    this.message = this.$t( "message.Error!Unexpected" );
                } );
            },
            /**
             * If the user hit Enter, confirmAccount.
             *
             * @param evt
             */
            checkSubmit( evt )
            {
                if( evt.code === "Enter" && this.valid )
                {
                    this.confirmAccount();
                }
            }
        }
    };
</script>

<style scoped>
</style>
