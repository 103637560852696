const uuidRegExp = "this space intentionally left blank";
module.exports = {
    "interface_version" : "1.0.0",
    "create" : {
        "app" : {
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : {
                        "type" : "string",
                        "pattern" : uuidRegExp
                    },
                    "appData" : {
                        "type" : "object",
                        "properties" : {
                            "display_name" : { "type" : "string", "minLength" : 1 },
                            "domain" : { "type" : "string", "format" : "hostname" },
                            "redirect_uris" : {
                                "type" : "array",
                                "items" : {
                                    "type" : "string",
                                    "format" : "uri"
                                },
                                "minItems" : 1,
                                "maxItems" : 100
                            },
                            "description" : { "type" : "string" },
                            "client_type" : { "type" : "string", "enum" : [ "confidential" ] },
                            "grant_types" : {
                                "type" : "array",
                                "items" : { "type" : "string", "enum" : [ "authorization_code", "refresh_token" ] },
                                "minItems" : 2,
                                "maxItems" : 2
                            },
                            "additionalProperties" : false,
                            "required" : [ "display_name", "domain", "redirect_uris", "client_type", "grant_types" ]
                        }
                    }

                },
                "additionalProperties" : false,
                "required" : [ "groupId", "appData" ]
            }
        }
    },
    "retrieve" : {
        "apps" : {
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : {
                        "type" : "string",
                        "pattern" : uuidRegExp
                    }
                },
                "additionalProperties" : false,
                "required" : [ "groupId" ]
            }
        }
    },
    "update" : {
        "app" : {
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "clientId" : { "type" : "string" },
                    "appData" : {
                        "type" : "object",
                        "properties" : {
                            "redirect_uris" : {
                                "type" : "array",
                                "items" : {
                                    "type" : "object",
                                    "properties" : {
                                        "scheme" : { "type" : "string", "enum" : [ "https://" ] },
                                        "path" : { "type" : "string", "pattern" : /^\/.*/ }
                                    },
                                    "required" : [ "scheme", "path" ]
                                },
                                "minItems" : 1,
                                "maxItems" : 100
                            },
                            "description" : { "type" : "string" },
                            "display_name" : { "type" : "string", "minLength" : 1 }
                        },
                        "additionalProperties" : false,
                        "required" : [ "clientId" ]
                    }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "appData" ]
            }
        },
        "client_secret" : {
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "clientId" : { "type" : "string" }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "clientId" ]
            }
        },
        "domain_validity" : {
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "clientId" : { "type" : "string" }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "clientId" ]
            }
        }
    },
    "delete" : {
        "app" : {
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "clientId" : { "type" : "string" }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "clientId" ]
            }
        }
    }
};