module.exports = {
    interface_version : "1.0.0",
    create : {
        user : {
            requestMapSchema : {
                type : "object",
                properties : {
                    email : { type : "string", format : "email" },
                    payload : {
                        type : "object",
                        properties : {
                            sub : { type : "string" },
                            email : { type : "string", format : "email" },
                            name : { type : "string" },
                            given_name : { type : "string" },
                            family_name : { type : "string" },
                            locale : { type : "string" },
                            preferred_username : { type : "string" },
                            picture : { type : "string", format : "url" },
                            organization : { type : "string" }
                        }
                    },
                    scope : { type : "string" }
                },
                required : [ "email", "payload" ],
                additionalProperties : false
            },
            handler : "route"
        },
        confirm_token : {
            requestMapSchema : {
                type : "object",
                properties : {
                    email : { type : "string", format : "email" },
                },
                additionalProperties : false,
                required : [ "email" ]
            },
            handler : "route"
        },
        confirmed_user : {
            requestMapSchema : {
                type : "object",
                properties : {
                    token : {
                        type : "string",
                        pattern : "\\w+"
                    },
                    email : {
                        type : "string",
                        format : "email"
                    },
                    password : {
                        type : "string",
                        pattern : "(^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{10,19}$)|(^.{20,128}$)"
                    }
                },
                additionalProperties : false,
                required : [ "token_value", "email", "password" ]
            },
            handler : "route"
        }
    }
};