<template>
  <v-card flat class="maxWidth-1100 ma-auto">
    <h1 v-if="view === 'list'" class="shio-section-title mt-2">{{ $t( 'client.OAuthApplications' ) }}</h1>
    <v-card-title @click="returnToList" v-if="view === 'edit'" style="cursor:pointer" class="py-0 mt-2">
      <h1 class="shio-section-title">
        <v-icon color="primary">mdi-chevron-left</v-icon>
        {{ $t( 'client.Application' ) }}
      </h1>
    </v-card-title>
    <v-responsive>
      <v-card class="d-flex flex-wrap" flat tile v-if="view === 'list'">
        <new-app-token class="ma-2 ma-lg-6 my-lg-5" @click="createApp"></new-app-token>
        <app-token v-for="item in items" :key="item.name" :entity="item" class="ma-2 ma-lg-6 my-lg-5" @click="openApp( item )"></app-token>
      </v-card>
      <div v-if="view === 'edit'">
        <app-card :entity="current_item" @close="returnToList()" @refresh-data="refreshData()"></app-card>
      </div>
    </v-responsive>
  </v-card>
</template>

<script>
    import AppToken from "./AppToken";
    import NewAppToken from "./NewAppToken";
    import AppCard from "./AppCard";
    export default {
        name : "start-flow",
        components : {
            "app-token" : AppToken,
            "new-app-token" : NewAppToken,
            "app-card" : AppCard
        },
        data()
        {
            return {
                groupId : $cc_user_info.organization.uuid,
                view : "list",
                items : [],
                current_item : undefined,
                item_template : {
                    $new : true,
                    grant_types : [ "authorization_code", "refresh_token" ],
                    client_type : "confidential",
                    display_name : ""
                }
            };
        },
        computed : {},
        methods : {
            fetchData()
            {
                this.$store.apiManagerClient.retrieveApps( this.groupId ).then( r =>
                {
                    this.items = r.apps;
                    if( this.current_item && !this.current_item.$new )
                    {
                        this.current_item = this.items.filter( item => item.name === this.current_item.name )[ 0 ];
                        if( this.view === 'edit' )
                        {
                            this.view = 'refresh';
                            this.view = 'edit';
                        }
                    }
                } ).catch( e =>
                {
                } );
            },
            returnToList()
            {
                this.fetchData();
                this.view = 'list';
            },
            refreshData()
            {
                this.fetchData();
                if( this.current_item.$new )
                {
                    this.current_item = { ...this.item_template };
                }
            },
            createApp()
            {
                this.current_item = { ...this.item_template };
                this.view = "edit";
            },
            openApp( app )
            {
                this.current_item = app;
                this.view = "edit";
            }
        },
        mounted()
        {
            this.fetchData();
        }
    }
</script>

<style scoped>

</style>
