import Vue from 'vue';
import Router from 'vue-router';
import signedOutRoutes from './signed-out-routes';
import apiRootRoutes from './api-root-routes';

Vue.use( Router );

function createRouter( routes )
{
    return new Router({
        routes: routes || []
    } );
}
const domain = window.location.host.split('.')[0];

const router = domain.startsWith( "api" ) ? createRouter( apiRootRoutes ) : createRouter( signedOutRoutes );

router.resetRoutes = function( routes )
{
    const newRoute = { ...router.currentRoute };
    router.matcher = createRouter( routes ).matcher;
    router.replace( { path : newRoute.path, query : newRoute.query } ).then( r => {} ).catch( e => {} );
};

export default router;
