<template>
  <div class="cvw-set-password-field">
    <v-text-field
        ref="input"
        :name="randomName"
        :disabled="disabled"
        v-model="inp_password"
        :type="show ? 'text' : 'password'"
        autocomplete="new-password"
        @focus="focused = true; $emit( 'focus' )"
        @blur="focused = false; $emit( 'blur' )"
        @keydown="emitKeydown"
        @input="onInput"
        :placeholder="my_placeholder"
        :label="my_label"
        :rules="rules"
    >
      <v-icon
          v-if="!show"
          slot="append"
          tabindex="-1"
          @click="toggleCloak()">mdi-eye
      </v-icon>
      <v-icon
          v-if="show"
          slot="append"
          tabindex="-1"
          @click="toggleCloak()">mdi-eye-off
      </v-icon>
    </v-text-field>
  </div>
</template>

<script>
    import tester from "owasp-password-strength-test";
    tester.config({
        allowPassphrases       : true,
        maxLength              : 128,
        minLength              : 10,
        minPhraseLength        : 15,
        minOptionalTestsToPass : 4,
    });
    export default {
        name : "signhero-set-password-field",
        props : {
            label : {
                type : String,
                default : ""
            },
            placeholder :
            {
                type : String,
                default : ""
            },
            disabled : {
                type : Boolean,
                default : false
            },
            messages : {
                type : Object,
                default : () => {}
            },
            rules : {
                type : Array,
                default : () => []
            },

        },
        data()
        {
            const labels = {};
            const msgs = this.messages || {};
            const keys = [ "PP!Passphrase",
                           "PP!OrPassphrase",
                           "PP!MinLength",
                           "PP!MaxLength",
                           "PP!RepeatingChars",
                           "PP!Lowercase",
                           "PP!Uppercase",
                           "PP!Numbers",
                           "PP!Specials",
                           "NewPassword" ];
            keys.forEach( key =>
            {
                labels[ key ] = msgs[ key ] || this.$t( 'message.' + key );
            } );
            return {
                my_placeholder : "",
                my_label : "",
                my_disabled : this.disabled,
                input : false,
                tooLong : false,
                password : "",
                inp_password : "",
                isPassphrase : false,
                show : false,
                focused : false,
                labels : labels,
            }
        },
        computed : {
            value()
            {
                return this.valid ? this.password : "";
            },
            valid()
            {
                this.password = this.password.replace( /[\u200B-\u200F\uFEFF]/g, '' );
                return tester.test( this.password ).strong;
            },
            /**
             * A random name for our password fields. This blocks autocomplete.
             */
            randomName()
            {
                return "RND" + new Date().getTime();
            }
        },
        methods : {
            emitKeydown( evt )
            {
                this.$emit( 'keydown', evt );
            },
            focus()
            {
                this.$refs.input.focus();
            },
            /**
             * Collects password into a property, while cloaking it in the input.
             * We set autocomplete="new-password" in order to use password suggestion in browser for SignHero
             * @param inp
             */
            onInput( inp )
            {
                this.password = this.inp_password;
                setTimeout( () =>
                {
                    this.$emit( "input", this.value );
                }, 1 );
            },
            toggleCloak()
            {
                this.show = !this.show;
                if( this.show )
                {
                    this.inp_password = this.password;
                }
                else
                {
                    this.cloakPassword();
                }
            },
            cloakPassword()
            {
                let masked = "";
                for( let i = 0; i < this.password.length; i++ )
                {
                    masked += '•';
                }
                this.inp_password = masked;
            }
        },
        mounted()
        {
            this.my_label = this.label;
            this.my_placeholder = this.placeholder || this.labels[ "NewPassword" ];
        }
    }
</script>

<style scoped>

</style>
