const uuidRegExp = "this space intetionally left blank";
import flowSchemas from "./flow-schemas";
const hyperlinkRegExp = /^((?!((([A-Za-z]{3,9}:(?:\/){2,})[A-Za-z0-9.-]+[A-Za-z0-9.-]+))).)*$/gi;
export default {
    "interface_version" : "2.0.0",
    "create" : {
        "sign_flow" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "process" : {
                        "type" : "object",
                        "properties" : {
                            "groupTitle" : { "type" : "string", pattern : hyperlinkRegExp },
                            "title" : { "type" : "string", pattern : hyperlinkRegExp },
                            "emailSubject" : { "type" : "string", pattern : hyperlinkRegExp },
                            "emailMessage" : { "type" : "string", pattern : hyperlinkRegExp },
                            "requester" : {
                                "type" : "object",
                                "properties" : {
                                    "name" : {
                                        "type" : "string"
                                    }
                                },
                                "additionalProperties" : false,
                                "required" : [ "name" ]
                            },
                            "locale" : { "type" : "string" },
                            "signatureLevel" : { "type" : "string", "enum" : [ "basic", "advanced" ] }
                        },
                        "required" : [ "groupTitle", "requester" ]
                    },
                    "signers" : {
                        "type" : "array",
                        "items" : { "oneOf" : [ flowSchemas.meSignerSchema, flowSchemas.othersSignerSchema ] }
                    },
                    "documents" : {
                        "type" : "array",
                        "items" : {
                            "type" : "object",
                            "properties" : {
                                "token" : {
                                    "type" : "string"
                                },
                                "fileName" : {
                                    "type" : "string"
                                },
                                "size" : {
                                    "type" : "number"
                                }
                            },
                            "additionalProperties" : false,
                            "required" : [ "token", "fileName" ]
                        }
                    }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "process", "signers", "documents" ]
            }
        },
        "sign_reminders" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid" ]
            }
        },
        "download_invitation" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "eventUuid" : { "type" : "string", "pattern" : uuidRegExp },
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid", "eventUuid" ]
            }
        },
        "email_template" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "emailSubject" : { "type" : "string" },
                    "emailMessage" : { "type" : "string" },
                    "nameTemplate" : { "type" : "string" }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "emailSubject", "emailMessage", "nameTemplate" ]
            }
        },
        "unsigned_digest" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "eventUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "docUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid", "eventUuid", "docUuid" ]
            }
        }
    },
    "retrieve" : {
        "sign_flow" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid" ]
            }
        },
        "invited_sign_flow" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "invitedGroupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "invitedGroupId", "processUuid" ]
            }
        },
        "sign_flows" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "searchScope" : { "type" : "string", "enum" : [ "group", "user", "invitations" ] },
                    "signFlowProperties" : {
                        "type" : "object",
                        "properties" : {
                            "searchScope" : { "type" : "string", "enum" : [ "group", "user" ] },
                            "limit" : { "type" : "integer", "minimum" : 1, "maximum" : 100 },
                            "orderBy" : { "type" : "string", "enum" : [ "created", "toc" ] },
                            "from" : { "type" : "integer", "minimum" : 0 },
                            "completed" : { "type" : "boolean", "enum" : [ true, false ] },
                            "canceled" : { "type" : "boolean", "enum" : [ true, false ] },
                            "declined" : { "type" : "boolean", "enum" : [ true, false ] },
                            "pendingOthers" : { "type" : "boolean", "enum" : [ true ] },
                            "pendingMe" : { "type" : "boolean", "enum" : [ true ] },
                            "tags" : {
                                "type" : "array",
                                "items" : { "type" : "string", "minLength" : 1, "maxLength" : 20 }
                            },
                        },
                        "additionalProperties" : false
                    }
                },
                "required" : [ "groupId", "searchScope", "signFlowProperties" ],
                "additionalProperties" : false
            }
        },
        "sign_flow_count" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "searchScope" : { "type" : "string", "enum" : [ "group", "user" ] },
                    "signFlowProperties" : {
                        "type" : "object",
                        "properties" : {
                            "searchScope" : { "type" : "string", "enum" : [ "group", "user" ] },
                            "limit" : { "type" : "integer", "minimum" : 1, "maximum" : 100 },
                            "from" : { "type" : "integer", "minimum" : 0 },
                            "completed" : { "type" : "boolean", "enum" : [ true, false ] },
                            "canceled" : { "type" : "boolean", "enum" : [ true, false ] },
                            "pendingOthers" : { "type" : "boolean", "enum" : [ true ] },
                            "pendingMe" : { "type" : "string", "pattern" : uuidRegExp },
                            "search" : { "type" : "string" },
                            "tags" : { "type" : "array", "items" : { "type" : "string", "minLength" : 1, "maxLength" : 20 } }
                        },
                        "additionalProperties" : false
                    }
                },
                "required" : [ "groupId", "searchScope", "signFlowProperties" ],
                "additionalProperties" : false
            }
        },
        "tags" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "searchScope" : { "type" : "string", "enum" : [ "group", "user" ] }
                },
                "required" : [ "groupId", "processUuid", "searchScope" ],
                "additionalProperties" : false
            }
        },
        "filter_tags" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "searchScope" : { "type" : "string", "enum" : [ "group", "user" ] }
                },
                "required" : [ "groupId", "searchScope" ],
                "additionalProperties" : false
            }
        },
        "counter_status" : {
            "handler" : "route",
            "scope" : "administer",
            "requestMapSchema" : {
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId" ]
            }
        },
        "signature_counter_status" : {
            "handler" : "route",
            "scope" : "administer",
            "requestMapSchema" : {
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId" ]
            }
        },
        "email_template" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "templateId" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "templateId" ]
            }
        },
        "email_templates" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId" ]
            }
        },
        "counter" : {
            "handler" : "route",
            "scope" : "administer",
            "requestMapSchema" : {
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "path" : { "type" : "string" },
                    "query" : {
                        "type" : "object",
                        "properties" : {
                            "statsFrom" : { "type" : "string", "format" : "date-time" },
                            "statsTo" : { "type" : "string", "format" : "date-time" },
                            "resolution" : {
                                "type" : "string",
                                "enum" : [ "second",
                                           "minute",
                                           "hour",
                                           "day",
                                           "week",
                                           "month",
                                           "quarter",
                                           "year",
                                           "decade",
                                           "century",
                                           "millennium" ]
                            },
                            "agg" : {
                                "type" : "string",
                                "enum" : [ "max", "min", "avg", "sum" ]
                            },
                            "userId" : { "type" : "string", "pattern" : uuidRegExp }
                        },
                        "required" : [ "statsFrom", "statsTo", "resolution" ],
                        "additionalProperties" : false
                    }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "path" ]
            }
        },
        "user_counter_summary" : {
            "handler" : "route",
            "scope" : "administer",
            "requestMapSchema" : {
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "statsFrom" : { "type" : "string", "format" : "date-time" },
                    "statsTo" : { "type" : "string", "format" : "date-time" }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "statsFrom", "statsTo" ]
            }
        },
        "user_signature_counter_summary" : {
            "handler" : "route",
            "scope" : "administer",
            "requestMapSchema" : {
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "statsFrom" : { "type" : "string", "format" : "date-time" },
                    "statsTo" : { "type" : "string", "format" : "date-time" },
                    "methods" : { "type" : "array", "items" : { "type" : "string", "minLength" : 1 } }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "statsFrom", "statsTo" ]
            }
        }
    },
    "delete" : {
        "sign_flow" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "reasons" : { "type" : "string" }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid" ]
            }
        },
        "invited_sign_flow" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "invitedGroupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "reasons" : { "type" : "string" }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid" ]
            }
        },
        "email_template" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "templateId" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "templateId" ]
            }
        }
    },
    "abort" : {
        "sign_flow" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "reasons" : { "type" : "string" }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid" ]
            }
        },
        "active_sign_flows" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "reasons" : { "type" : "string" }
                },
                "additionalProperties" : false,
                "required" : [ "groupId" ]
            }
        }
    },
    "update" : {
        "sign_flow_tags" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "tags" : {
                        "type" : "array",
                        "items" : { "type" : "string", "minLength" : 1, "maxLength" : 20 }
                    }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid" ]
            }
        },
        "email_template" : {
            "handler" : "route",
            "scope" : "access",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "templateId" : { "type" : "string", "pattern" : uuidRegExp },
                    "emailSubject" : { "type" : "string" },
                    "emailMessage" : { "type" : "string" },
                    "nameTemplate" : { "type" : "string" }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "templateId", "emailSubject", "emailMessage", "nameTemplate" ]
            }
        }
    }
};
