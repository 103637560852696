<template>
  <v-card flat class="place-signature-panel">
    <v-chip-group mandatory>
      <v-chip outlined label v-for="document in documents" :key="document.token" :value="document" @click="setCurrentDocument( document )" :color="document.signatures_placed ? 'green' : 'gray'">
        <v-icon>mdi-file-pdf-box</v-icon>
        {{ document.fileName }}
      </v-chip>
    </v-chip-group>
    <sh-placeable-signature-card ref="placerCard" v-if="current_document" :document="current_document"
                                 :signatureLevel="signatureLevel"
                                 :signers="signers" @signature-placed="onPlaceSignature"/>
    <v-row no-gutters class="my-5">
      <v-col cols="12" sm="6" md="4" class="pa-2">
        <v-btn block @click="$router.back()" class="secondary-btn">{{ $t( "signflow.ButtonBack" ) }}</v-btn>
      </v-col>
      <v-col class="hidden-sm-and-down" cols="4">
        <v-spacer/>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pa-2">
        <v-btn block @click="createSignFlow()" :disabled="!ready_to_send" class="primary-btn ml-auto">
          <span v-if="flowType === 'only_me'">{{ $t( "signflow.GoToSign" ) }}</span>
          <span v-if="flowType !== 'only_me'">{{ $t( "signflow.Send" ) }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay v-if="is_working">
      <v-progress-circular size="48" indeterminate/>
    </v-overlay>
  </v-card>
</template>

<script>
    import flowMixin from "./flow-mixin";
    import PlaceableSignatureCard from "../../../pdfsign/PlaceableSignatureCard";
    import { globalEvents } from "@cloudlace/client";
    let onServerMessage = 0;
    export default {
        name : "PlaceSignatures",
        components : {
            "sh-placeable-signature-card" : PlaceableSignatureCard
        },
        mixins : [ flowMixin ],
        data()
        {
            const flowData = this.$store.flow_to_create ? this.$store.flow_to_create : { documents : [] };
            return {
                is_working : false,
                current_document : flowData.documents[ 0 ],
                documents : flowData.documents,
                extras : flowData.extras,
                use_drag_and_drop : flowData.use_drag_and_drop,
                choose_signature : flowData.choose_signature,
                flowType : flowData.flowType,
                flowTitle : flowData.flowTitle,
                signers : flowData.signers,
                emailSubject : flowData.emailSubject,
                emailMessage : flowData.emailMessage,
                signatureLevel : flowData.signatureLevel
            }
        },
        computed : {
            ready_to_send()
            {
                return this.documents.filter( doc => doc.signatures_placed ).length === this.documents.length;
            }
        },
        methods : {
            /**
             * Stub, called by mixin. Nothing to reset, so do nothing.
             */
            reset()
            {
            },
            /**
             * Unset .is_working and go back to the start-flow pane.
             */
            terminate()
            {
                this.is_working = false;
                this.$router.replace( "/start-flow" );
            },
            /**
             * If doc isn't already loaded, set it as .current_document and load it into the placerCard.
             *
             * @param doc {Object}
             */
            setCurrentDocument( doc )
            {
                if( doc !== this.current_document && this.$refs.placerCard )
                {
                    this.current_document = doc;
                    this.$refs.placerCard.loadPdf( doc );
                }
            },
            /**
             * Assign the signature properties from argument to the member of this.documents it matches.
             *
             * @param doc
             */
            onPlaceSignature( doc )
            {
                for( let i = 0; i < this.documents.length; i++ )
                {
                    const document = this.documents[ i ];
                    if( document.token === doc.token )
                    {
                        Object.assign( document, doc );
                    }
                    this.$set( this.documents, i, document );
                }
            }
        },
        /**
         * If there's no document, go back home. (This should only happen if the user reloads the page.)
         * Set listener for relevant server messages
         */
        mounted()
        {
            if( !this.current_document )
            {
                this.$router.replace( '/' );
            }
        },
    }
</script>

<style lang="sass" scoped>
  .place-signature-panel
    max-width: 1100px
    margin: auto
</style>
